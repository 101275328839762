import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { LoginResponse } from '../models/login-response';
import { BehaviorSubject, Observable } from 'rxjs';
import { PasswordResetRequest } from '../models/password-reset-request';
import { PermissionGuard } from './permission.guards';
import { SAMLService } from './saml.service';
import { LogoutService } from './logout.service';

export const TOKEN_NAME: string = 'jwt';

@Injectable()
export class AuthService {

    private url: string = environment.apiEndpoint + '/authenticate';
    private headers = new Headers({ 'Content-Type': 'application/json' });
    private isUserLoggedIn = new BehaviorSubject<boolean>(true);

    constructor(private httpClient: HttpClient, private toast: ToastrService, private router: Router,
        private permissionGuard: PermissionGuard, private samlService: SAMLService, private logoutService: LogoutService) {

    }

    getToken(): string {
        try {
            return localStorage.getItem(TOKEN_NAME);
        } catch (e) {
            this.toast.error('Failed on getting token');
            this.logOut();
        }
    }

    getTenant(): string {
        return this.getUserFromToken().split('\\')[0].toLowerCase();
    }

    setToken(token: string): void {
        localStorage.setItem(TOKEN_NAME, token);
    }

    getTokenExpirationDate(token: string): Date {
        try {
            const decoded: any = jwt_decode(token);
            if (decoded.exp === undefined) {
                return null;
            }

            const date = new Date(0);
            date.setUTCSeconds(decoded.exp);
            return date;
        } catch {
            this.logOut();
        }
    }

    isTokenExpired(token?: string): boolean {
        try {
            if (!token) {
                token = this.getToken();
            }
            if (!token) {
                return true;
            }

            const date = this.getTokenExpirationDate(token);
            if (date === undefined) {
                return true;
            }
            return !(date.valueOf() > new Date().valueOf());
        } catch (e) {
            this.logOut();
        }
    }

    getSellerIdFromToken() {
        try {
            const token = this.getToken();
            const decoded: any = jwt_decode(token);
            if (decoded.sellerId === undefined) {
                return null;
            }
            return decoded.sellerId;

        } catch (e) {
            this.logOut();
        }
    }

    changePassword(context) {
        return this.httpClient.post(this.url + '/changePassword', context);
    }

    forgotUsername(context) {
        return this.httpClient.post(this.url + '/forgotUsername', context);
    }

    forgotPassword(context) {
        return this.httpClient.post(this.url + '/forgotPassword', context);
    }

    getPasswordResetRequest(context): Observable<PasswordResetRequest> {
        return this.httpClient.post<PasswordResetRequest>(this.url + '/passwordResetRequest', context);
    }

    resetPassword(context) {
        return this.httpClient.post(this.url + '/resetPassword', context);
    }

    isUserValid(): boolean {
        return !this.isTokenExpired();
    }

    getUserFromToken(): string {
        try {
            const token = this.getToken();
            const decoded: any = jwt_decode(token);
            if (decoded.sub === undefined) {
                return null;
            }
            return decoded.sub;
        } catch (e) {
            this.toast.error('Failed on getting user');
            this.logOut();
        }
    }

    login(user) {
        this.setUserLoggedIn();
        return this.httpClient.post<LoginResponse>(`${this.url}/login`, user, { observe: 'response' });
    }

    removeToken() {
        localStorage.removeItem(TOKEN_NAME);
    }

    logOut() {
        this.logoutService.logOut().subscribe();
        this.setUserLoggedOut();
        this.removeToken();
        this.permissionGuard.resetOnLogout();
        this.router.navigate(['/login-form']);
        this.logOutSSOUser();
        sessionStorage.clear();
    }

    logOutSSOUser() {
        this.samlService.logOutSsoUser().subscribe(res => {});
    }

    setLocalStorageAfterLogin(): void {
        // On login, if user had not previously toggled on 'pick up where you left off' option, then we reset the period in local storage
        if (localStorage.getItem('persistDateAccountSelection') !== true.toString()) {
            localStorage.removeItem('beginDate');
            localStorage.removeItem('endDate');
        }
    }

    getLoginState(): Observable<boolean> {
        return this.isUserLoggedIn.asObservable();
    }

    setUserLoggedOut() {
        this.isUserLoggedIn.next(false);
    }

    setUserLoggedIn() {
        this.isUserLoggedIn.next(true);
    }
}


