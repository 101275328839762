<dx-popup
    #emailResultsPopup
    [height]="430"
    [width]="600"
    [showTitle]="true"
    title="Results of Sending Emails"
    [hideOnOutsideClick]="false"
    [showCloseButton]="true"
    [(visible)]="popupVisible"
    class="email-popup email-results-popup">
    <dx-responsive-box>
        <dxi-row [ratio]="10"></dxi-row>
        <dxi-row [ratio]="1"></dxi-row>

        <dxi-col [ratio]="1"></dxi-col>
        <dxi-col [ratio]="1"></dxi-col>
        <dxi-col [ratio]="1"></dxi-col>
        <dxi-col [ratio]="1"></dxi-col>
        <dxi-col [ratio]="1"></dxi-col>

        <dxi-item class="email-results-grid">
            <dxi-location [row]="0" [col]="0" [colspan]="5"></dxi-location>

            <dx-data-grid [dataSource]="_results" [height]="305">
                <dxo-scrolling mode="virtual"></dxo-scrolling>
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-sorting mode="multiple"></dxo-sorting>
                <dxi-column dataField="this" [width]="70" cellTemplate="successTemplate" caption="" [allowFiltering]="false" [allowSorting]="false"></dxi-column>
                <dxi-column dataField="sellerName" caption="Name" [width]="180"></dxi-column>
                <dxi-column dataField="sellerEmailAddress" caption="Email"></dxi-column>

                <div *dxTemplate="let data of 'successTemplate'">
                    <div *ngIf="data.value.success === true">
                        <div id="success-icon-{{data.value.sellerId}}" class="icon-cell success">
                            <i class="dx-icon dx-icon-check"></i>
                        </div>
                        <dx-tooltip target="#success-icon-{{data.value.sellerId}}" showEvent="dxhoverstart" hideEvent="dxhoverend" position="top">
                            <div *dxTemplate="let contentData of 'content'">
                                <p>Email was sent successfully</p>
                            </div>
                        </dx-tooltip>
                    </div>
                    <div *ngIf="data.value.success === false">
                        <div id="failure-icon-{{data.value.sellerId}}" class="icon-cell failure">
                            <i class="dx-icon dx-icon-clear"></i>
                        </div>
                        <dx-tooltip target="#failure-icon-{{data.value.sellerId}}" showEvent="dxhoverstart" hideEvent="dxhoverend" position="top">
                            <div *dxTemplate="let contentData of 'content'">
                                <p>{{data.value.errorMessage}}</p>
                            </div>
                        </dx-tooltip>
                    </div>
                </div>
            </dx-data-grid>
        </dxi-item>

        <dxi-item class="ok-button">
            <dxi-location [row]="1" [col]="2"></dxi-location>
            <dx-button text="OK" (onClick)="popupVisible = false"></dx-button>
        </dxi-item>
    </dx-responsive-box>
</dx-popup>
