import { Component, ElementRef, Input, OnChanges, OnInit } from '@angular/core';
import { CoreFeature } from 'src/app/shared/constants/enums';
import { CoreColumn } from 'src/app/shared/models/core-column';
import { GridProps } from 'src/app/shared/models/core-data-grid-properties';
import { DateRange } from 'src/app/shared/models/date-range';
import { ProcessLogForAdminPopup } from 'src/app/shared/models/process-log-for-admin-popup';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { ProcessLogService } from 'src/app/shared/services/process-log.service';
import { HelperService } from '../../services/helper.service';
@Component({
    selector: 'app-process-log-grid',
    templateUrl: './process-log-grid.component.html',
    styleUrls: ['./process-log-grid.component.scss']
})

export class ProcessLogGridComponent implements OnInit, OnChanges {
    @Input() dateRange;
    @Input() height: string = '';

    processLogGridProps: GridProps = new GridProps('processLogGrid', null, false, true).enableExport(true, 'logs').setColumnAutoWidth(false);
    processLogs: ProcessLogForAdminPopup[] = [];
    processLogColumns: CoreColumn[] = [
        new CoreColumn('processLogId', 'Log ID', true).sortDesc(1).setWidth(60),
        new CoreColumn('transactionId', '', false),
        new CoreColumn('processLogRecordTypeId', '', false),
        new CoreColumn('processName', 'Process', true, 'string').setWidth(160),
        new CoreColumn('log', 'Log', true, 'string'),
        new CoreColumn('date', 'Log Date', true, 'datetime', null, false, 'MM/dd/yyyy hh:mm:ss a').sortDesc(0).setWidth(100),
        new CoreColumn('userName', 'User Name', false),
        new CoreColumn('loginName', 'Login Name', true, 'string').setWidth(120),
        new CoreColumn('controllerName', 'Controller Name', true, 'string').setWidth(140),
        new CoreColumn('recordType', 'Record Type', true, 'string').setWidth(100),
        new CoreColumn('durationInMs', 'Duration In Ms', true).setWidth(100),
    ];

    permissionViewProcessLog: boolean;

    constructor(private helperService: HelperService,
        private processLogService: ProcessLogService,
        private permissionService: PermissionService,
        private elementRef: ElementRef) {
    }

    ngOnInit() {
        this.processLogGridProps.height = this.height;
        this.permissionViewProcessLog = this.permissionService.checkCurrentUserPermission(CoreFeature.ViewLogs.toString());
    }

    ngOnChanges(e: any) {
        if(this.dateRange){
            this.getDataForProcessLogGrid();
        }
    }

    getDataForProcessLogGrid(): void {
        this.processLogService.getProcessLogsByDateRange(this.dateRange).subscribe(logs => {
            this.processLogs = logs.result;
        });
    }
}
