<div>
    <dx-accordion
        collapsible="true"
        [disabled]="versionGridDisabled"
        selectedItem="null"
        class="accordion">
        <dxi-item [title]="versionAccordionTitle" #versionsGridItem>
            <div *dxTemplate class="versionsgrid">
                <core-data-grid
                    #versionGrid
                    id="versionGrid"
                    [dataSource]="ruleVersions"
                    [columns]="versionColumns"
                    [props]="versionGridProps">
                </core-data-grid>
            </div>
        </dxi-item>
    </dx-accordion>
    <dx-popup title="Issues Detected" [(visible)]="isIssuePopupVisible" [width]="700" [height]="500" [showCloseButton]="true" [wrapperAttr]="{ class: 'issue-popup' }">
        Your changes to rule versions have not yet been saved, as we detected some inconsistencies.
        <br />
        <br />
        <div>{{issueMessage}}</div>
        <br />
        Please fix these issues and save again.
    </dx-popup>
    <dx-popup title="Stale Output" [(visible)]="isStalePopupVisible" [width]="400" [height]="500" [showCloseButton]="true" [wrapperAttr]="{ class: 'issue-popup' }">
        <dx-scroll-view>
            Stale periods:<br>
            <div>{{this.stalePeriodsString}}</div><br>
            Changing the version date does not delete calculated output.<br>
            Re-process the period(s) to remove stale output.
        </dx-scroll-view>
    </dx-popup>
</div>