import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CoreResponse } from '../models/core-response';
import { SellerImport } from '../models/seller-import';

@Injectable()
export class SellerImportService {
    importURL: string;
    private baseurl: string = environment.apiEndpoint;
    private sellerImportURL = this.baseurl + '/sellerImport/';

    constructor(private http: HttpClient) {
        this.importURL = `${this.sellerImportURL}import`;
    }

    getSellerImports(sellerId: number): Observable<SellerImport[]> {
        return this.http.get<SellerImport[]>(this.sellerImportURL + 'sellerImports/' + sellerId.toString());
    }
}
