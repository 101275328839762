import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Xaction } from '../models/xaction';
import { CoreProperty } from '../models/core-property';
import { HelperService } from './helper.service';
import { dynamicXactionColumns, importStr } from '../constants/constants';
import { Tag } from '../models/tag';
import { GroupableGroup } from '../models/groupable-group';
import { Product } from '../models/product';
import { Customer } from '../models/customer';
import { SellerName } from '../models/seller-name';
import { XactionForProcessed } from '../models/xaction-for-processed';
import { Period } from '../models/period';
import { CoreRequest } from '../models/core-request';
import { CoreColumn } from '../models/core-column';
import { FieldService } from '../services/field.service';
import { Singleton } from '../models/singleton';
import { Seller } from '../models/seller';
import { ColumnType } from '../models/core-column-type';
import { UpdateXactionFlat } from '../models/UpdateXactionFlat';
import { IServerSideGetRowsRequest } from 'ag-grid-community';
import { AgGridServerSideRowsResponse } from '../models/ag-grid-server-side-rows-response';
import { AgGridFilterValuesRequest } from '../models/ag-grid-filter-values-request';
import { AgGridFilterValuesResponse } from '../models/ag-grid-filter-values-response';
import { GetFlatXactionForProcessing } from '../models/get-flat-xaction-for-processing';
import { CoreResponse } from '../models/core-response';

@Injectable()
export class XactionService {
    private baseurl: string = environment.apiEndpoint;
    private xactionURL = this.baseurl + '/xaction/';
    private lastRowRequest: any;

    constructor(private http: HttpClient, private helperService: HelperService, private fieldsService: FieldService) {
    }

    getXactionFromDatasource(dataSourceId: number, periodId?: number, seriesId?: number): Observable<Xaction[]> {
        let url = this.xactionURL + 'bydatasource/' + dataSourceId + '/';
        if (seriesId) {
            url += periodId + '/' + seriesId;
        }

        return this.http.get<Xaction[]>(url);
    }

    getFlatXactionForProcessing = (getFlatXactionForProcessing: GetFlatXactionForProcessing): Observable<CoreResponse<AgGridServerSideRowsResponse>> => {
        const url = this.xactionURL + 'getXactionForProcessingFlat';
        this.lastRowRequest = getFlatXactionForProcessing;
        return this.http.post<CoreResponse<AgGridServerSideRowsResponse>>(url, new CoreRequest<GetFlatXactionForProcessing>(getFlatXactionForProcessing, null));
    };

    getExcelExport(visibleColumns: string[], fileType: string, sheetName: string, withImport: boolean): Observable<Blob> {
        const url = `${this.xactionURL}getExcelExport/${fileType}/${encodeURIComponent(sheetName)}/${withImport}`;
        this.lastRowRequest.visibleColumns = visibleColumns;
        return this.http.post<Blob>(url, new CoreRequest<GetFlatXactionForProcessing>(this.lastRowRequest, null), { responseType:'blob' as 'json' });
    };

    getXactionMappings(): Observable<any> {
        return this.http.get<any>(this.xactionURL + 'mappings');
    }

    getAllXactionColumns(isProcessed: boolean): Observable<Record<string,string>> {
        return this.http.get<Record<string,string>>(this.xactionURL + `columns?isProcessed=${isProcessed}`);
    }

    getXactionCountByPeriodSeries(args: any): Observable<CoreResponse<number>>{
        return this.http.post<CoreResponse<number>>(this.xactionURL + 'count/', args);
    }

    getColumnFilterValues(request: AgGridFilterValuesRequest): Observable<AgGridFilterValuesResponse> {
        return this.http.post<AgGridFilterValuesResponse>(this.xactionURL + 'columnFilterValues', request);
    }

    insertXaction(xaction: Xaction) {
        return this.http.post<Xaction>(this.xactionURL, new CoreRequest<Xaction>(xaction, null));
    }

    insertFlatXaction(flatXaction: any): Observable<CoreResponse<any>> {
        return this.http.post<CoreResponse<any>>(`${this.xactionURL}flat`, flatXaction);
    }

    insertFlatXactions(flatXactions: any[]): Observable<CoreResponse<any[]>> {
        return this.http.post<CoreResponse<any>>(`${this.xactionURL}flats`, JSON.stringify(flatXactions));
    }

    insertXactions(xactions: Xaction[]) {
        return this.http.post<Xaction[]>(this.xactionURL + 'many', new CoreRequest<Xaction>(null, xactions));
    }

    updateXaction(xaction: Xaction) {
        return this.http.put<number>(this.xactionURL + 'update', xaction);
    }

    updateXactions(xactions: Xaction[]) {
        return this.http.post<CoreResponse<number>>(this.xactionURL + 'updatemany', new CoreRequest<Xaction>(null, xactions));
    }

    deleteXactions(ids: number[]) {
        return this.http.post<CoreResponse<number>>(this.xactionURL + 'deletemanybyids', ids);
    }

    fillXactionFields(xaction: Xaction, row: any) {
        Object.entries(row).forEach(([refName, value]) => {
            const category = refName.split('_')[0] + (refName.includes('seller') ? 'Id' : '') + 'Fields';
            value = value === null ? null : value.toString();
            xaction[category].push({refName, value} as CoreProperty);
        });
        return xaction;
    }

    xactionToRow(xaction: Xaction, tags?: Tag[], sellers?: SellerName[], productGroups?: GroupableGroup[], customerGroups?: GroupableGroup[]) {
        const row = { ...xaction };
        const flatFields = [].concat(...Object.keys(xaction).filter(k => k.includes('Fields')).map(k => xaction[k]));
        Object.keys(row).filter(k => k.includes('Fields')).forEach(k => delete row[k]);

        if (row.period && row.period.beginDate && row.period.endDate) {
            row.periodRange = this.helperService.createDateRangeString(row.period.beginDate, row.period.endDate);
        }

        if (row.customer && row.customer.name && row.customer.customerGroupId) {
            row.customerName = row.customer.name;
            row.customerImportName = row.customer.importName;
            row.customerGroupName = customerGroups.filter(x => x.id === row.customer.customerGroupId)[0].name;
        }

        if (row.product && row.product.name && row.product.productGroupId) {
            row.productName = row.product.name;
            row.productImportName = row.product.importName;
            row.productGroupName = productGroups.filter(x => x.id === row.product.productGroupId)[0].name;
        }

        flatFields.forEach(f => {
            const refName = f.refName;
            let value = f.value;
            if ((refName.includes('qty') || refName.includes('id')) && (!isNaN(parseInt(value, 10)) || refName.includes('seller_id_'))) {
                if (tags && tags.length > 0 && refName.includes('tag_id_')) {
                    value = tags.filter(x => x.id === parseInt(value, 10))[0].name;
                } else if (sellers && sellers.length > 0 && refName.includes('seller_id_')) {
                    row[refName + '_import'] = isNaN(parseInt(value, 10)) ? null : sellers.filter(x => x.id === parseInt(value, 10))[0].importName;
                    value = isNaN(parseInt(value, 10)) ? null : sellers.filter(x => x.id === parseInt(value, 10))[0].name;
                } else {
                    value = isNaN(parseFloat(value)) ? null : parseFloat(value);
                }
            } else if (refName.includes('date') && value) {
                value = new Date(value);
            }
            row[refName] = value;
        });
        return row;
    }

    flattenXactionData(data: Xaction[], returnColumnProp: boolean = false, tags?: Tag[], sellers?: SellerName[],
        productGroups?: GroupableGroup[], customerGroups?: GroupableGroup[]): {data: Xaction[], columns: any} {
        const res = { data: null, columns: null };
        if (!returnColumnProp) {
            res.data = data.map(x => this.xactionToRow(x));
            return res;
        } else {
            const mappedResults = data.map(x => this.xactionToRow(x, tags, sellers, productGroups, customerGroups));
            res.columns = this.helperService.getEmptyColumnsObject(mappedResults, dynamicXactionColumns);
            res.data = mappedResults;

            return res;
        }
    }

    processedToRow(data: XactionForProcessed, tags: Tag[], sellers: SellerName[],
        productGroups: GroupableGroup[], customerGroups: GroupableGroup[], periods: Period[]) {

        if (this.helperService.isNullOrUndefined(data.xaction.period)) {
            data.xaction.period = periods.find(x => x.id === data.calculation.periodId);
        }

        const result: any = this.xactionToRow(data.xaction, tags, sellers, productGroups, customerGroups);
        result.function = data.function;
        result.function['isPayoutString'] = data.function.isPayout ? 'Payment' : 'Info';
        result.calculation = data.calculation;
        result.isGeneratedInsert = data.isGeneratedInsert;

        return result;
    }

    getXactionColumnProps(colMappings: any, colName: string): { friendlyName: string, dataType: string } {
        const mapping = dynamicXactionColumns.filter(x => colName.indexOf(x.columnPattern) !== -1)[0];
        const prop = this.getXactionCorePropertyName(mapping.columnPattern);
        let shouldInheritProps = false;

        if (colName.indexOf('_import') !== -1) {
            shouldInheritProps = true;
        }

        return {
            friendlyName: (colMappings[prop]
                .filter(x => x.refName === (!shouldInheritProps ? colName : colName.replace('_import', '')))[0]?.friendlyName + (!shouldInheritProps ? '' : importStr)),
            dataType: mapping.dataType
        };
    }

    getXactionCorePropertyName(columnPattern: string): string {
        return columnPattern === dynamicXactionColumns[0].columnPattern ? 'dateFields'
            : columnPattern === dynamicXactionColumns[1].columnPattern ? 'qtyFields'
            : columnPattern === dynamicXactionColumns[2].columnPattern ? 'tagFields'
            : columnPattern === dynamicXactionColumns[3].columnPattern ? 'textFields'
            : columnPattern === dynamicXactionColumns[4].columnPattern ? 'sellerIdFields'
            : '';
    }

    updateXactionAndCoreProperties(oldData: any, newData: any, tags?: Tag[], sellers?: SellerName[],
        products?: Product[], customers?: Customer[]): Xaction {
        const xAction = new Xaction().createXaction(oldData);

        Object.keys(newData).forEach(prop => {
            const dynamicColName = dynamicXactionColumns.filter(x => prop.indexOf(x.columnPattern) !== -1);
            if (dynamicColName.length > 0) {
                const newProp = new CoreProperty();
                newProp.refName = prop;

                // note - all value props must be converted to a string
                // tag
                if (dynamicColName[0].columnPattern === dynamicXactionColumns[2].columnPattern) {
                    newProp.value = tags.filter(x => x.name === newData[prop])[0].id + '';
                // seller
                } else if (dynamicColName[0].columnPattern === dynamicXactionColumns[4].columnPattern) {
                    const newId = sellers.filter(x => x.name === newData[prop])[0].id;
                    newProp.value = newId === null ? null : newId + '';
                // date
                } else if (dynamicColName[0].columnPattern === dynamicXactionColumns[0].columnPattern) {
                    newProp.value = this.helperService.dateToUTCISOString(newData[prop]);
                // all others
                } else {
                    newProp.value = newData[prop] + '';
                }

                xAction[this.getXactionCorePropertyName(dynamicColName[0].columnPattern)].push(newProp);
            } else {
                if (prop === 'productName') {
                    xAction.productId = products.filter(x => x.name === newData[prop])[0].id;
                } else if (prop === 'customerName') {
                    xAction.customerId = customers.filter(x => x.name === newData[prop])[0].id;
                } else if (xAction.hasOwnProperty(prop)) {
                    xAction[prop] = newData[prop];
                }
            }
        });

        return xAction;
    }

    updateXactionFlat(flatXactions: UpdateXactionFlat[]): Observable<CoreResponse<Record<string,string>>> {
        return this.http.patch<CoreResponse<Record<string,string>>>(this.xactionURL, flatXactions);
    }

    createCoreColumn(refName?: any, displayName?: any, editable: any = true): CoreColumn {
        const dataType = dynamicXactionColumns.find(x => refName.startsWith(x.columnPattern)).dataType;
        return new CoreColumn(refName, displayName, true, dataType, null, editable);
    }

    setCoreColumnsFormats(columns: CoreColumn[], fields: Singleton[]) {
        columns.forEach(c => c.format = this.fieldsService.convertFormatString(fields.find(f => f.refName === c.dataField).format));
    }

    setCoreColumnsLookups(columns: CoreColumn[], tags: Tag[] = [], sellers: Seller[] = [], sellersActive: Seller[] = []) {
        tags = tags.sort((a, b) => (a.name > b.name) ? 1 : -1);
        sellers = sellers.sort((a, b) => (a.name > b.name) ? 1 : -1);
        sellersActive = sellersActive.sort((a, b) => (a.name > b.name) ? 1 : -1);

        columns.filter(x => x.dataField.includes('tag_id'))
            .forEach(x => x.columnType = new ColumnType().createLookup(tags.filter(t => t.tagNo === +x.dataField.split('_').pop()), 'id', 'name', true));
        columns.filter(x => x.dataField.includes('seller_id'))
            .forEach(x => x.columnType = new ColumnType().createLookup(sellers, 'id', 'name', true));

        if (sellersActive.length) {
            columns.filter(x => x.dataField.includes('seller_id') && x.isEditable)
                .forEach(x => x.columnType = new ColumnType().createLookup(sellersActive, 'id', 'name', true));
        }
    }
}
