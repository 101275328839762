import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Message } from '../models/message';
import { CoreResponse } from '../models/core-response';

@Injectable({
    providedIn: 'root'
})

export class MessageService {
    private baseurl: string = environment.apiEndpoint;
    private messageURL = this.baseurl + '/message/';

    constructor(private http: HttpClient) { }

    getMessages(): Observable<Message[]> {
        return this.http.get<Message[]>(this.messageURL);
    }

    updateMessage(message: Message): Observable<Message> {
        return this.http.put<Message>(this.messageURL, message);
    }
}
