import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, NgModule, OnInit, ViewChild, ViewEncapsulation, Output, EventEmitter, AfterViewInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { ReportViewModel } from 'devexpress-reporting/dx-reportdesigner';
import { DxReportDesignerComponent, DxReportDesignerModule, DxReportViewerComponent, DxReportViewerModule } from 'devexpress-reporting-angular';
import { DxLoadPanelModule, DxSelectBoxModule, DxCheckBoxModule, DxPopupModule,
    DxResponsiveBoxModule, DxTextBoxModule, DxButtonModule } from 'devextreme-angular';
import { environment } from '../../../../environments/environment';
import { toastrConstants } from '../../../shared/constants/constants';
import { ReportContext } from '../../models/contexts/report-context';
import { ReportLayoutContext } from '../../models/contexts/report-layout-context';
import { PeriodService } from '../../services/period.service';
import { ReportService } from '../../services/report.service';
import { SellerService } from '../../services/seller.service';
import { RecurrenceService } from '../../services/recurrence.service';
import { AuthService } from '../../services/auth.service';
import { HelperService } from '../../services/helper.service';
import { AppElementsService } from '../../services/app-element.service';
import { ReportLayout } from '../../models/report-layout';
import { Recurrence } from '../../models/recurrence';
import { CoreFeature, coreResponseCodes, EnumBucketType } from '../../constants/enums';
import { ajaxSetup } from '@devexpress/analytics-core/analytics-utils';
import { ReportSegment } from '../../models/report-segment';
import { GridProps } from '../../models/core-data-grid-properties';
import { CoreColumn } from '../../models/core-column';
import { CoreComponentModule } from '../core-component.module';
import { EditModes } from '../../constants/dev-extreme-enums';
import { CoreGridComponent } from '../core-data-grid/core-data-grid.component';
import { BuildingBlockHelperService } from 'src/app/pages/building-blocks/building-block-helper.service';
import { DistributionList } from '../../models/distribution-list';
import { DistributionListService } from '../../services/distribution-list.service';

@Component({
    selector: 'app-report-designer',
    templateUrl: './report-designer.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./report-designer.component.scss',
        '../../../../../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.common.css',
        '../../../../../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.light.css',
        '../../../../../node_modules/@devexpress/analytics-core/dist/css/dx-querybuilder.css',
        '../../../../../node_modules/devexpress-reporting/dist/css/dx-webdocumentviewer.css',
        '../../../../../node_modules/devexpress-reporting/dist/css/dx-reportdesigner.css'],
    providers: [AuthService, ReportService, SellerService]
})
export class ReportDesignerComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() showLoadingPanel: boolean;
    @Input() hasAdminView: boolean;
    @Input() isDesignMode: boolean;
    @Output() onExitDesigner = new EventEmitter();
    @Output() onReportNameChanged = new EventEmitter<string>();
    @Output() onNewReport = new EventEmitter<any>();
    @Output() onSaveReport = new EventEmitter();
    @Output() onSaveAsNewReport = new EventEmitter<string>();
    @Output() onDeleteReport = new EventEmitter();
    @Output() onShowInactiveSegmentsChange = new EventEmitter();
    @Output() onLoadingStarted = new EventEmitter();
    @Output() onLoadingCompleted = new EventEmitter();

    @ViewChild(DxReportDesignerComponent, { static: true })
    reportDesigner: DxReportDesignerComponent;
    @ViewChild(CoreGridComponent, { static: true })
    segmentsGrid: CoreGridComponent;

    username: string;
    reportUrl: string;
    reportPreviewUrl: string;
    designerHeight: string = '800px';
    loadingVisible: boolean;
    pageDestroyed: boolean = false;
    hostUrl: string = environment.apiEndpoint;
    invokeAction: string = '/DXXRDV';
    token = localStorage.getItem('jwt');
    getDesignerModelAction = '/ReportDesignerSetup/GetReportDesignerModel';

    reportLayoutId: number;
    reportLayout: ReportLayout = new ReportLayout();
    newReportName: string;
    recurrences: Recurrence[];
    recurrenceId: number;
    globalDistributionLists: DistributionList[];
    defaultUiViewId: number;
    showInactiveSegments: boolean = false;
    ignoreShowInactiveSegmentsChanges: boolean = false;
    isPaymentReport: boolean = false;
    includeZeroes: boolean = false;
    isPublic: boolean = false;
    functionString: string;
    ruleString: string;
    attributes: string;
    segments: ReportSegment[];
    reportSegments: ReportSegment[];
    segmentsPopupVisible: boolean = false;
    segmentsPopupMinHeight: number = 350;
    segmentsPopupHeight: number = 500;
    segmentsPopupHeightDifference: number = 140;
    segmentsGridProps: GridProps;
    segmentsGridColumns: CoreColumn[];
    viewActiveOnly: boolean = true;
    segmentsPopupOkButtonOptions: any;
    previewPopupVisible: boolean = false;
    isPreviewLandscape: boolean = false;
    saveBody: string;
    reportConfigId: string;
    reportNames: Array<string>;
    toastConsts = toastrConstants;
    hasUnsavedCustomChanges: boolean = false;
    deletePopupVisible: boolean = false;
    renamePopupVisible: boolean = false;
    readonly reportBucketType = EnumBucketType.Report;

    constructor(private toast: ToastrService,
        private http: HttpClient,
        private authService: AuthService,
        private helperService: HelperService,
        private reportService: ReportService,
        private sellerService: SellerService,
        private periodService: PeriodService,
        private recurrenceService: RecurrenceService,
        private distributionListService: DistributionListService,
        private buildingBlocksService: BuildingBlockHelperService,
        private appElementsService: AppElementsService) {
    }

    ngOnInit() {
        ajaxSetup.ajaxSettings = {
            beforeSend: (jqxhr, settings) => {
                jqxhr.setRequestHeader('Authorization', 'Bearer ' + this.token);
            },
            headers: {
                Authorization: `Bearer ${this.token}`
            }
        };

        this.username = this.authService.getUserFromToken();
        this.segmentsGridProps = new GridProps('segments', null, false, true, false, false, true, false, null, null);
        this.segmentsGridProps.isScrollingEnabled = true;
        this.segmentsGridProps.height = (this.segmentsPopupHeight - this.segmentsPopupHeightDifference);
        this.segmentsGridProps.isPagingEnabled = false;
        this.segmentsGridProps.editMode = EditModes.Batch;
        this.segmentsGridProps.isUpdatingAllowed = true;
        this.segmentsGridProps.hideSaveButton = true;
        this.segmentsGridProps.hideBulkEditButton = true;
        this.segmentsGridProps.footerToolbarItems = [{
            location: 'before',
            widget: 'dxCheckBox',
            name: 'viewActiveOnly',
            locateInMenu: 'never',
            options: {
                text: 'View active only',
                value: this.viewActiveOnly,
                onValueChanged: (e) => {
                    this.viewActiveOnly = e.value;
                    this.prepareSegmentsPopup();
                }
            }
        }];
        this.segmentsGridColumns = [
            new CoreColumn('id', '', false, 'number', null, false),
            new CoreColumn('selected', '', true, 'boolean', null, true),
            new CoreColumn('name', 'Name', true, 'string', null, false),
            new CoreColumn('isActive', 'Active?', true, 'boolean', null, false),
            new CoreColumn('isPayment', 'Payment?', true, 'boolean', null, false),
            new CoreColumn('isRule', 'Rule?', true, 'boolean', null, false),
        ];
        this.segmentsPopupOkButtonOptions = {
            text: 'OK',
            onClick: () => {
                if (this.segmentsGrid.grid.instance.hasEditData()) {
                    this.hasUnsavedCustomChanges = true;
                }
                this.segmentsPopupVisible = false;
            }
        };

        const sideNavChanges: Subscription = this.appElementsService.getSideNavOpenChanges().subscribe(x => {
            if (!this.pageDestroyed) {
                setTimeout(() => {
                    // Dispatching a window resize event, which triggers DevEx report designer to resize its components
                    // This prevents unusable gray area, and allows user to utilize more of the screen as they collapse the left nav menu
                    window.dispatchEvent(new Event('resize'));
                }, this.appElementsService.sideNavOuterToolbar.animationDuration + 1);
            } else {
                sideNavChanges.unsubscribe();
            }
        });
    }

    ngAfterViewInit(): void {
        this.setDesignerHeight();
    }

    ngOnDestroy() {
        this.pageDestroyed = true;
    }

    createReport() {
        this.loadingVisible = true;
        this.reportLayoutId = 0;

        this.designReport();
    }

    editReport(reportLayoutId: number): void {
        this.reportLayoutId = reportLayoutId;
        this.designReport();
    }

    designReport(unsavedChanges: boolean = false): void {
        if (!this.username) {
            return;
        }

        const storedSellers = localStorage.getItem(`${this.username}.selectedSellers`);
        const storedBeginDate = new Date(localStorage.getItem('beginDate'));
        const storedEndDate = new Date(localStorage.getItem('endDate'));

        this.reportService.getNewReportConfigId().subscribe(newReportUrl => {

            const serviceCalls = [ this.sellerService.getSeller(this.username) ];
            if (unsavedChanges) {
                this.reportConfigId = newReportUrl;
                this.saveBody = this.getReportConfigSaveBody();
                serviceCalls.push(this.reportService.postReportConfig(this.saveBody));
            }
            forkJoin(serviceCalls).subscribe(([seller, postReportResult = undefined]) => {

                if (storedSellers == null) {
                    localStorage.setItem(`${this.username}.selectedSellers`, '[' + seller.id + ']');
                }

                this.periodService.setStorageDates(seller.id, seller?.subordinates?.map(x => x?.id), this.username, true).then(() => {
                    this.loadingVisible = true;
                    const context = new ReportContext();
                    context.id = this.reportLayoutId;
                    context.beginDate = storedBeginDate;
                    context.endDate = storedEndDate;
                    context.selectedSellers = localStorage.getItem(`${this.username}.selectedSellers`);
                    context.token = this.authService.getUserFromToken();
                    context.isDesigner = true;
                    context.reportLayout = new ReportLayout();
                    context.reportLayout.showInactiveSegments = this.showInactiveSegments;

                    if (unsavedChanges && postReportResult !== undefined) {
                        context.reportConfigId = this.reportConfigId;
                    }

                    if (this.reportLayoutId > 0) {
                        this.reportService.getReportAsByteArray(context).subscribe(response => {
                            if (response.responseCode === coreResponseCodes.Success) {
                                this.reportService.loadReport(response.result).subscribe(result => {
                                    this.reportUrl = result.toString();
                                    this.loadingVisible = false;
                                    this.onLoadingCompleted.emit();
                                }, error => console.error(error));
                            } else {
                                this.toast.error(response.message);
                                this.loadingVisible = false;
                                this.onLoadingCompleted.emit();
                            }
                        });
                    } else {
                        this.reportUrl = undefined;
                        this.loadingVisible = false;
                        this.onLoadingCompleted.emit();
                    }

                    forkJoin([this.recurrenceService.GetAllPublishedRecurrences(),
                        this.distributionListService.getGlobalDistributionLists(),
                        this.reportService.getReportSegments(),
                        this.getReport(this.reportLayoutId)
                    ]).subscribe(([recurrences, globalDistributionLists, segments, report]) => {
                        this.recurrences = recurrences;
                        this.recurrences.unshift(new Recurrence(0, '<None>', undefined, undefined, undefined, undefined));
                        this.globalDistributionLists = globalDistributionLists;
                        this.globalDistributionLists.unshift(new DistributionList(0, '<None>'));
                        this.segments = segments;

                        this.reportLayout = report;
                        if (unsavedChanges) {
                            this.reportLayout.showInactiveSegments = this.showInactiveSegments;
                            this.resetUnsavedChangesStatus(true);
                        } else {
                            this.ignoreShowInactiveSegmentsChanges = true;
                            this.showInactiveSegments = this.reportLayout.showInactiveSegments;
                            setTimeout(() => {
                                this.ignoreShowInactiveSegmentsChanges = false;
                            }, 100);
                        }
                        this.isPaymentReport = report.isPayment;
                        this.includeZeroes = report.includeZeroes;
                        this.isPublic = report.isPublic;
                        this.recurrenceId = report.recurrenceId === undefined || report.recurrenceId === null ? 0 : report.recurrenceId;
                        this.defaultUiViewId = this.reportLayout.defaultUiViewId === undefined || this.reportLayout.defaultUiViewId === null ? 0 : this.reportLayout.defaultUiViewId;
                        this.functionString = this.reportLayout.functionString;
                        this.ruleString = this.reportLayout.ruleString;
                        this.attributes = this.reportLayout.attributes;

                        this.prepareSegmentsPopup();

                        setTimeout(() => {
                            this.hasUnsavedCustomChanges = false;
                        }, 10);
                    });

                    this.reportService.getReportsBySeller(seller.id, storedBeginDate, storedEndDate).subscribe(reports => {
                        this.reportNames = [];
                        reports.forEach(report => {
                            this.reportNames.push(report.name);
                        });
                    });
                });
            });
        });
    }

    getReport(reportId: number): Observable<ReportLayout> {
        if (reportId === 0) {
            const report = new ReportLayout();
            report.reportParId = reportId;
            report.isPayment = false;
            report.includeZeroes = false;
            report.isPublic = false;
            report.functionString = '';
            report.ruleString = '';
            report.attributes = '';
            report.fieldString = '';
            report.defaultSeriesId = 1;
            report.showInactiveSegments = false;
            report.contextCode = 0;
            report.recordSource = 0;
            report.defaultUiViewId = 0;
            return of(report);
        } else {
            return this.reportService.getReport(reportId);
        }
    }

    customizeMenuActions(event: any): void {
        // allows rename to be saved without body changes
        this.getMenuActionById('dxrd-save', event.args.Actions).disabled = false;

        // set functions for menu items that we want to overwrite
        this.setMenuActionClickById('dxrd-exit', event.args.Actions, (model: ReportViewModel, actionItem: any) => {
            this.onExitClick(model, actionItem);
        });
        this.setMenuActionClickById('dxrd-save', event.args.Actions, (model: ReportViewModel, actionItem: any) => {
            this.onSaveReport.emit();
        });
        this.setMenuActionClickById('dxrd-save-as', event.args.Actions, (model: ReportViewModel, actionItem: any) => {
            this.onSaveAsNewClick();
        });

        // add new custom menu items
        this.addCustomMenuAction(event.args.Actions, 'custom-dxrd-rename', 'Rename', 'web-report-edit-icon', (model: ReportViewModel, actionItem: any) => {
            this.openRenamePopup();
        });
        this.addCustomMenuAction(event.args.Actions, 'custom-dxrd-delete', 'Delete', 'dxrd-svg-toolbar-delete', (model: ReportViewModel, actionItem: any) => {
            this.deletePopupVisible = true;
        });

        // rename 'Save As' item to 'Save As New'
        this.renameMenuActionById('dxrd-save-as', event.args.Actions, 'Save As New');

        // relatively reorder menu items
        this.precedeMenuActionIndex(event.args.Actions, 'dxrd-save-as', 'custom-dxrd-rename');
        this.precedeMenuActionIndex(event.args.Actions, 'custom-dxrd-rename', 'custom-dxrd-delete');
        this.precedeMenuActionIndex(event.args.Actions, 'custom-dxrd-delete', 'dxrd-add-multi-query-sql-datasource');
        this.precedeMenuActionIndex(event.args.Actions, 'dxrd-add-multi-query-sql-datasource', 'dxrd-localization-editor');
        this.precedeMenuActionIndex(event.args.Actions, 'dxrd-localization-editor', 'dxrd-exit');

        // hide unneeded menu items
        this.hideMenuActionById('dxrd-newreport', event.args.Actions);
        this.hideMenuActionById('dxrd-newreport-via-wizard', event.args.Actions);
        this.hideMenuActionById('dxrd-open-report', event.args.Actions);
        this.hideMenuActionById('dxrd-run-wizard', event.args.Actions);

        // hide admin actions for non-admins
        if (!this.hasAdminView) {
            this.hideMenuActionById('dxrd-save', event.args.Actions);
            this.hideMenuActionById('dxrd-save-as', event.args.Actions);
            this.hideMenuActionById('custom-dxrd-rename', event.args.Actions);
            this.hideMenuActionById('custom-dxrd-delete', event.args.Actions);
        }
    }

    getMenuActionById(id: string, actions: Array<any>): any {
        const filteredActions = actions.filter(x => x.id === id);
        if (filteredActions !== undefined && filteredActions.length > 0) {
            return filteredActions[0];
        }
        return undefined;
    }

    hideMenuActionById(id: string, actions: Array<any>): any {
        const action = this.getMenuActionById(id, actions);
        if (action !== undefined) {
            action.visible = false;
        }
    }

    setMenuActionClickById(id: string, actions: Array<any>, method: (model: ReportViewModel, actionItem: any) => void): void {
        const action = this.getMenuActionById(id, actions);
        if (action !== undefined) {
            action.clickAction = method;
        }
    }

    renameMenuActionById(id: string, actions: Array<any>, text: string): void {
        const action = this.getMenuActionById(id, actions);
        if (action !== undefined) {
            action.text = text;
            action.displayText = () => text;
        }
    }

    precedeMenuActionIndex(actions: Array<any>, precedingId: string, relocatingId: string): void {
        const oldIndex = actions.findIndex(x => x.id === relocatingId);
        const newIndex = actions.findIndex(x => x.id === precedingId) + 1;
        const relocatingItem = actions[oldIndex];
        if (relocatingItem) {
            actions.splice(oldIndex, 1);
            actions.splice(newIndex, 0, relocatingItem);
        }
    }

    addCustomMenuAction(actions: Array<any>, id: string, text: string, imageTemplateName: string, clickAction: (model: ReportViewModel, actionItem: any) => void): void {
        actions.push({
            container: 'menu',
            id,
            text,
            imageTemplateName,
            clickAction,
            visible: true,
            disabled: false
        });
    }

    customizeElements(event: any): void {
        this.setToolbarItemClickById('dxrd-preview', event.args.Elements, () => {
            this.onPreviewClick();
        });
        this.setToolbarItemVisibility('dxrd-preview', event.args.Elements, false);
    }

    getToolbarItem(id: string, elements: any[]): any {
        const toolbar = elements.find(element => element.id === 'dxrd-toolbar-template-base');
        const toolbarItem = toolbar.model.actionLists.toolbarItems.find(item => item.id === id);
        return toolbarItem;
    }

    setToolbarItemVisibility(id: string, elements: any[], visible: boolean): void {
        const toolbarItem = this.getToolbarItem(id, elements);
        if (toolbarItem !== undefined) {
            toolbarItem.visible = visible;
        }
    }

    setToolbarItemClickById(id: string, elements: any[], method: () => void): void {
        const toolbarItem = this.getToolbarItem(id, elements);
        if (toolbarItem !== undefined) {
            toolbarItem.clickAction = method;
        }
    }

    reportNameExists(newName: string): boolean {
        return this.reportNames !== undefined && this.reportNames.filter(x => x === newName).length > 0;
    }

    onNewClick(model: ReportViewModel, actionItem: any, newReportClickAction: () => void): void {
        const event = {
            model,
            clickAction: newReportClickAction
        };
        this.onNewReport.emit(event);
    }

    onExitClick(model: ReportViewModel, actionItem: any): void {
        this.onExitDesigner.emit();
    }

    onSaveAsNewClick(): void {
        let index = 1;
        let newReportName = this.reportLayout.name;
        while (this.reportNameExists(newReportName)) {
            newReportName = this.reportLayout.name + ` - Copy${index < 2 ? '' : ' ('+ index +')'}`;
            index++;
        }
        this.reportLayout.name = newReportName;
        this.onSaveAsNewReport.emit(newReportName);
    }

    getReportConfigSaveBody(): string {
        const designerModel = this.reportDesigner.GetDesignerModel();
        const reportViewModel: ReportViewModel = designerModel.model();
        this.isPreviewLandscape = reportViewModel.landscape();
        const serializedReportObject = {
            XtraReportsLayoutSerializer: reportViewModel.serialize()
        };
        const serializedReportJSON = JSON.stringify(serializedReportObject);
        const reportObject = {
            reportLayout: serializedReportJSON,
            reportUrl: this.reportConfigId
        };
        const encodedReportJSON = encodeURIComponent(encodeURIComponent(JSON.stringify(reportObject)));
        return `actionKey=setNewData&arg=${encodedReportJSON}`;
    }

    exitDesigner(e): void {
        this.appElementsService.headerComponent.unsavedChanges = false;
        this.hasUnsavedCustomChanges = false;
    }

    openRenamePopup(): void {
        this.newReportName = this.reportLayout.name;
        this.renamePopupVisible = true;

        setTimeout(() => {
            const doc = document as any;
            if (doc.querySelector('.report-designer-rename-text input[type="text"]')) {
                doc.querySelector('.report-designer-rename-text input[type="text"]').select();
            }
        }, 550);
    }

    renameReport(): void {
        this.reportLayout.name = this.newReportName;
        this.hasUnsavedCustomChanges = true;
        this.renamePopupVisible = false;
    }

    cancelRename(): void {
        this.renamePopupVisible = false;

        setTimeout(() => {
            this.newReportName = this.reportLayout.name;
        }, 500);
    }

    discardReportChanges(): void {
        // there are no longer any unsaved changes
        this.hasUnsavedCustomChanges = false;
        this.appElementsService.headerComponent.unsavedChanges = false;
    }

    showInactiveSegmentsChange(e): void {
        if (!this.ignoreShowInactiveSegmentsChanges) {
            this.hasUnsavedCustomChanges = true;
            this.reportLayout.showInactiveSegments = this.showInactiveSegments;
            this.onShowInactiveSegmentsChange.emit();
        }
    }

    isPaymentReportChange(): void {
        this.reportParameterChange();
        this.prepareSegmentsPopup();
    }

    reportParameterChange(): void {
        this.hasUnsavedCustomChanges = true;
    }

    cancelSave(): void {
        this.saveBody = '';
    }

    prepareForSave(): void {
        this.setSegmentsValues();
        this.reportLayout.functionString = this.functionString;
        this.reportLayout.ruleString = this.ruleString;
        this.reportLayout.attributes = this.attributes;
        this.reportLayout.isPayment = this.isPaymentReport;
        this.reportLayout.includeZeroes = this.includeZeroes;
        this.reportLayout.isPublic = this.isPublic;
        this.reportLayout.recurrenceId = this.recurrenceId;
        this.reportLayout.defaultUiViewId = this.defaultUiViewId;
        this.reportLayout.showInactiveSegments = this.showInactiveSegments;
    }

    checkForUnsavedChanges(): void {
        if (!this.appElementsService.headerComponent.unsavedChanges) {
            this.appElementsService.headerComponent.unsavedChanges = this.hasUnsavedChanges();
        }
    }

    hasUnsavedChanges(): boolean {
        const designerModel = this.reportDesigner.GetDesignerModel();
        return designerModel.isDirty() || this.hasUnsavedCustomChanges;
    }

    resetUnsavedChangesStatus(status: boolean = false): void {
        if (this.reportDesigner) {
            const designerModel = this.reportDesigner.GetDesignerModel();
            if (designerModel) {
                designerModel.isDirty(status);
            }
        }
    }

    onPreviewClick(): void {
        this.onLoadingStarted.emit();
        this.loadingVisible = true;
        this.reportService.getNewReportConfigId().subscribe(newReportUrl => {

            this.reportConfigId = newReportUrl;
            this.saveBody = this.getReportConfigSaveBody();

            const username = this.authService.getUserFromToken();
            this.sellerService.getSellerWithSubordinateSetting(username).subscribe(seller => {
                this.periodService.setStorageDates(seller.id, seller?.subordinates?.map(x => x?.id), this.username, false).then(() => {
                    this.loadingVisible = true;
                    const context = new ReportContext();
                    context.id = this.reportLayoutId;
                    context.reportConfigId = this.reportConfigId;
                    context.beginDate = new Date(localStorage.getItem('beginDate') + 'Z');
                    context.endDate = new Date(localStorage.getItem('endDate') + 'Z');
                    context.selectedSellers = localStorage.getItem(`${this.username}.selectedSellers`);
                    context.token = this.authService.getUserFromToken();
                    context.isDesigner = false;
                    context.reportLayout = this.reportLayout;
                    context.reportLayout.isPayment = this.isPaymentReport;
                    context.reportLayout.includeZeroes = this.includeZeroes;
                    context.reportLayout.isPublic = this.isPublic;
                    context.reportLayout.recurrenceId = this.recurrenceId;
                    context.reportLayout.showInactiveSegments = this.showInactiveSegments;
                    context.reportLayout.functionString = this.segmentsGrid.grid.instance.hasEditData()
                        ? this.setSegmentValue(this.functionString, false) : this.functionString;
                    context.reportLayout.ruleString = this.segmentsGrid.grid.instance.hasEditData()
                        ? this.setSegmentValue(this.ruleString, true) : this.ruleString;
                    context.reportLayout.attributes = this.attributes;

                    this.reportService.postReportConfig(this.saveBody).subscribe(postConfigResult => {
                        if (postConfigResult.success === true) {
                            this.reportService.previewReport(context).subscribe(result => {
                                this.http.post<number>(environment.apiEndpoint + '/report/', result).subscribe(resultUrl => {
                                    this.reportPreviewUrl = resultUrl.toString();
                                    this.previewPopupVisible = true;
                                    this.loadingVisible = false;
                                    this.onLoadingCompleted.emit();
                                }, error => console.error(error));
                            }, error => {
                                this.toast.error('An error occurred while attempting to create preview');
                                this.loadingVisible = false;
                                this.onLoadingCompleted.emit();
                            });
                        }
                    });

                });
            });
        });
    }

    deleteReport(): void {
        this.onDeleteReport.emit();
    }

    prepareSegmentsPopup(): void {
        const functionIds = this.helperService.parseBracketedIdString(this.functionString);
        const ruleIds = this.helperService.parseBracketedIdString(this.ruleString);

        this.reportSegments = [];
        this.segments
            .filter(x => (x.isActive || !this.viewActiveOnly) && (x.isPayment || !this.isPaymentReport))
            .sort((a, b) => a.name.localeCompare(b.name))
            .forEach(segment => {
                segment.selected = (segment.isRule && ruleIds.find(x => x === segment.id) !== undefined)
                    || (!segment.isRule && functionIds.find(x => x === segment.id) !== undefined);
                this.reportSegments.push(segment);
            }
        );
    }

    setSegmentsValues(): void {
        if (this.segmentsGrid.grid.instance.hasEditData()) {
            this.functionString = this.setSegmentValue(this.functionString, false);
            this.ruleString = this.setSegmentValue(this.ruleString, true);
        }
    }

    setSegmentValue(existingValue: string, isRule: boolean) {
        const existingSegmentIds = this.helperService.parseBracketedIdString(existingValue);
        const deletedSegmentIds = [];
        this.segmentsGridProps.gridChanges.filter(x => x.data?.selected === false && x.key?.isRule === isRule)
            .forEach(x => {
                if(isRule){
                    deletedSegmentIds.push(...this.buildingBlocksService.getAllRuleVersionsById(x.key.id).map(v => +v.id.replace('Co', '')));
                } else {
                    deletedSegmentIds.push(x.key.id);
                }
            });
        const newSegmentIds = [];
        this.segmentsGridProps.gridChanges.filter(x => x.data?.selected === true && x.key?.isRule === isRule)
            .forEach(x => {
                if(isRule){
                    newSegmentIds.push(...this.buildingBlocksService.getAllRuleVersionsById(x.key.id).map(v => +v.id.replace('Co', '')));
                } else {
                    newSegmentIds.push(x.key.id);
                }
            });
        const updatedSegmentIds = [...new Set(existingSegmentIds
                                    .filter(x => !deletedSegmentIds.includes(x))
                                    .concat(newSegmentIds)
                                    .sort((a, b) => a - b))];
        return this.helperService.createBracketedIdString(updatedSegmentIds);
    }

    onFunctionsClick(): void {
        // Do something if in full screen mode?
        this.segmentsPopupVisible = true;
    }

    customizePreviewMenuActions(e) {
        e.args.Actions.find(action => action.id === 'dxxrp-print').visible = false;
        e.args.Actions.find(action => action.id === 'dxxrp-print-page').visible = false;
        e.args.Actions.find(action => action.id === 'dxxrp-export-menu').visible = false;
        e.args.Actions.find(action => action.id === 'dxxrp-search').visible = false;
        e.args.Actions.find(action => action.id === 'dxxrp-highlight-editing-fields').visible = false;
        e.args.Actions.find(action => action.id === 'dxrd-fullscreen').hasSeparator = true;
    }

    customizePreviewExportOptions(e) {
        e.args.HideExportOptionsPanel();
    }

    onResizeSegmentsPopup(e): void {
        const height = e.height > this.segmentsPopupMinHeight ? e.height : this.segmentsPopupMinHeight;
        this.segmentsGridProps.height = (height - this.segmentsPopupHeightDifference);
    }

    setDesignerHeight(): void {
        const doc = document as any;
        const viewportHeight = doc.querySelector('.dx-viewport').offsetHeight;
        this.designerHeight = `${Math.max(viewportHeight - 56 - 43 - 5 - 63, 100)}px`;
    }

    onWindowResize(): void {
        this.setDesignerHeight();
    }
}

@NgModule({
    imports: [
        DxReportDesignerModule,
        DxReportViewerModule,
        DxLoadPanelModule,
        DxSelectBoxModule,
        DxCheckBoxModule,
        DxPopupModule,
        DxResponsiveBoxModule,
        DxTextBoxModule,
        DxButtonModule,
        CoreComponentModule,
    ],
    declarations: [ReportDesignerComponent],
    exports: [ReportDesignerComponent]
})
export class ReportDesignerModule { }
