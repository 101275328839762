import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Singleton } from '../models/singleton';
import { Tag } from '../models/tag';
import { CalcQty } from '../models/calc-qty';
import { Product } from '../models/product';
import { Customer } from '../models/customer';
import { ProductGroup } from '../models/product-group';
import { CustomerGroup } from '../models/customer-group';
import { CoreResponse } from '../models/core-response';
import { filter, map } from 'rxjs/operators';
import { singletonClassIds } from '../constants/enums';

@Injectable()
export class FieldService {
    tagImportURL: string;
    private singletonURL = environment.apiEndpoint + '/Singleton/';
    private tagURL = environment.apiEndpoint + '/Tag/';
    private calcQtyURL = environment.apiEndpoint + '/CalcQty/';
    private productUrl = environment.apiEndpoint + '/product/';
    private customerUrl = environment.apiEndpoint + '/customer/';
    private productGroupUrl = environment.apiEndpoint + '/product_group/';
    private customerGroupUrl = environment.apiEndpoint + '/customer_group/';

    constructor(private http: HttpClient) {
        this.tagImportURL = `${this.tagURL}import`;
    }

    getSingletons(): Observable<Singleton[]> {
        return this.http.get<Singleton[]>(this.singletonURL);
    }

    getSingletonsByClassId(singletonClassId: number): Observable<Singleton[]> {
        return this.http.get<Singleton[]>(this.singletonURL + 'singletonsByClassId/' + singletonClassId);
    }

    insertSingleton(singleton: Singleton): Observable<Singleton> {
        return this.http.post<Singleton>(this.singletonURL, singleton);
    }

    updateSingleton(singleton: Singleton): Observable<Singleton> {
        return this.http.put<Singleton>(this.singletonURL, singleton);
    }

    deleteSingleton(id: number): Observable<CoreResponse<any>> {
        return this.http.delete<CoreResponse<any>>(this.singletonURL + id);
    }

    getTags(): Observable<Tag[]> {
        return this.http.get<Tag[]>(this.tagURL);
    }

    getDeletedTagIds(): Observable<number[]> {
        return this.http.get<number[]>(this.tagURL + 'deletedTagIds');
    }

    insertTag(tag: Tag): Observable<Tag> {
        return this.http.post<Tag>(this.tagURL, tag);
    }

    updateTag(tag: Tag): Observable<Tag> {
        return this.http.put<Tag>(this.tagURL, tag);
    }

    deleteTag(id: number): Observable<CoreResponse<any>> {
        return this.http.delete<CoreResponse<any>>(this.tagURL + id);
    }

    getCalcQtys(): Observable<CalcQty[]> {
        return this.http.get<CalcQty[]>(this.calcQtyURL);
    }

    getConstantCalcQtys(): Observable<CalcQty[]> {
        return this.http.get<CalcQty[]>(this.calcQtyURL + 'constant');
    }

    insertCalcQty(calcQty: CalcQty): Observable<CalcQty> {
        return this.http.post<CalcQty>(this.calcQtyURL, calcQty);
    }

    updateCalcQty(calcQty: CalcQty): Observable<CalcQty> {
        return this.http.put<CalcQty>(this.calcQtyURL, calcQty);
    }

    deleteCalcQty(id: number): Observable<CoreResponse<any>> {
        return this.http.delete<CoreResponse<any>>(this.calcQtyURL + id);
    }

    getSingletonDeleteConstraints(id: number): Observable<any> {
        return this.http.get<any>(this.singletonURL + 'Delete-Constraints/' + id);
    }

    getCalcQtyDeleteConstraints(id: number): Observable<any> {
        return this.http.get<any>(this.calcQtyURL + 'Delete-Constraints/' + id);
    }

    getFieldNameConstraints(name: string): Observable<any> {
        return this.http.get<any>(this.singletonURL + 'Name-Constraints/' + encodeURIComponent(name));
    }

    getIsMappedColumnNvarcharMax(columnName: string){
        return this.http.get<CoreResponse<boolean>>(this.singletonURL + `isFieldNvarcharMax/${columnName}`);
    }

    setColumnDataTypeNvarcharMax(column: Singleton){
        return this.http.post<CoreResponse<string>>(this.singletonURL + `setFieldNvarcharMax`, column);
    }

    convertFormats(fields: any): any {
        fields.forEach(field => {
            field.format = this.singletonToFormatString(field.format, field.singletonClassId);
        });

        return fields;
    }

    singletonToFormatString(format: string, singletonClassId: number) {
        const numericSeparatorRE = /#,([0#])(\b|$)/g;

        format = format ? format : '';
        if (format) {
            if (!singletonClassId || singletonClassId === 2) {
                format = format.toLowerCase();
                if (format === 'c') {
                    format = '$#,##0.00;($#,##0.00)';
                } else if (format === 'p') {
                    format = 'Percent';
                } else if (format.match(numericSeparatorRE)) {
                    format = format.replace(numericSeparatorRE, '#,##$1');
                }
            } else if (singletonClassId === 3) {
                if (format === 'f') {
                    format = 'longDateLongTime';
                }
            }
        }

        return format;
    }

    singletonToSummaryFormatString(format: any, singletonClassId: number) {
        const numericSeparatorRE = /#,([0#])(\b|$)/g;

        format = format ? format : '';
        if (format) {
            if (!singletonClassId || singletonClassId === 2) {
                format = format.toLowerCase();
                if (format === 'c') {
                    format = { type: 'currency', precision: 2 };// 'currency';
                } else if (format === 'p') {
                    format = 'Percent';
                } else if (format.match(numericSeparatorRE)) {
                    format = format.replace(numericSeparatorRE, '#,##$1');
                }
            } else if (singletonClassId === 3) {
                if (format === 'f') {
                    format = 'longDateLongTime';
                }
            }
        }

        return format;
    }

    convertFormatString(formatString: string): string {
        const numericSeparatorRE = /#,([0#])(\b|$)/g;
        let result = formatString ? formatString : '';
        if (result.toLowerCase() === 'c') {
            result = '$#,##0.00;($#,##0.00)';
        } else if (result.toLowerCase() === 'p') {
            result = 'percent';
        } else if (result === 'f') {
            result = 'longDateLongTime';
        } else if (result.match(numericSeparatorRE)) {
            result = result.replace(numericSeparatorRE, '#,##$1');
        }
        return result;
    }

    getTagMapping(): Observable<any[]> {
        return this.http.get<any[]>(this.tagURL + 'GetTagMapping');
    }

    getAllProducts(): Observable<Product[]> {
        return this.http.get<Product[]>(this.productUrl);
    }

    getAllCustomers(): Observable<Customer[]> {
        return this.http.get<Customer[]>(this.customerUrl);
    }

    getAllProductGroups(): Observable<ProductGroup[]> {
        return this.http.get<ProductGroup[]>(this.productGroupUrl);
    }

    getAllCustomerGroups(): Observable<CustomerGroup[]> {
        return this.http.get<CustomerGroup[]>(this.customerGroupUrl);
    }

    getFieldNames(): Observable<string[]> {
        return this.getSingletons().pipe(map(singletons => singletons
            .filter(x => x.singletonClassId in singletonClassIds
                && x.singletonClassId !== singletonClassIds.EmailTest
                && x.value !== '')
            .map(x => x.value)
            .sort((a, b) => a.localeCompare(b))));
    }

    splitTagsByField(tags: Tag[]): any {
        const splitTags = {};

        for (const tag of tags) {
            tag.visible = !tag.isDeleted;
            if (splitTags[tag.tagNo] === undefined) {
                splitTags[tag.tagNo] = [tag];
            } else {
                splitTags[tag.tagNo].push(tag);
            }
        }

        return splitTags;
    }
}
