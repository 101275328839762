import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { BuildingBlockHelperService } from '../building-block-helper.service';
import { Container, Group } from 'src/app/shared/models/building-blocks';
import { RuleVersionAuditResults } from 'src/app/shared/models/rule-version-audit-results';
import { GridProps } from 'src/app/shared/models/core-data-grid-properties';
import { DevExpressDMLFunctions } from 'src/app/shared/models/dev-express-dml-functions';
import { CoreEventArguments } from 'src/app/shared/models/core-event-arguments';
import { PeriodService } from 'src/app/shared/services/period.service';
import { Period } from 'src/app/shared/models/period';
import { CoreGridComponent } from 'src/app/shared/components/core-data-grid/core-data-grid.component';
import { ToastrService } from 'ngx-toastr';
import { devExtremeDMLStrings } from 'src/app/shared/constants/dev-extreme-enums';
import { RuleVersionBatchChanges } from 'src/app/shared/models/rule-version-batch-changes';
import { containerTypeIds } from 'src/app/shared/constants/enums';
import { CoreDataGridChange } from 'src/app/shared/models/core-data-grid-change';
import { CoreColumn } from 'src/app/shared/models/core-column';
import { HelperService } from 'src/app/shared/services/helper.service';
import { ColumnType } from 'src/app/shared/models/core-column-type';
import { BuildingBlocksService } from 'src/app/shared/services/building-blocks.service';
import { filter } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { CoreResponse } from 'src/app/shared/models/core-response';

@Component({
	selector: 'app-bb-version-selector',
	templateUrl: './bb-version-selector.component.html',
	styleUrls: ['./bb-version-selector.component.scss']
})
export class BbVersionSelectorComponent implements OnInit {
    @ViewChild('versionGrid', {static: false}) versionGrid: CoreGridComponent;
	versionGridDisabled: boolean = false;
	isIssuePopupVisible: boolean = false;
	issueMessage: string = '';
	selectedRuleVersion: Container;
	ruleVersions: Container[] = [];
	versionColumns: any = null;
	versionGridProps: GridProps = null;
    versionAccordionTitle: string = 'Versions';
	everyPeriod: Period[];
	hasUnsavedGridChanges: boolean = false;
    distinctPeriodBegins: Period[];
    distinctPeriodEnds: Period[];
    isStalePopupVisible: boolean = false;
    stalePeriodsString: string = '';
    scopeContainer: Container;

	beginDateEditorOptions: any = {
        displayFormat: {
            parser: (value) => this.helperService.parseDate(value),
            formatter: (date) => this.helperService.formatDateDefault(date)
        }
    };
    endDateEditorOptions: any = {
        showClearButton: true,
        displayFormat: {
            parser: (value) => this.helperService.parseDate(value),
            formatter: (date) => this.helperService.formatDateDefault(date)
        }
    };
	constructor(
		private buildingBlockHelper: BuildingBlockHelperService,
		private buildingBlockService: BuildingBlocksService,
		private periodService: PeriodService,
		private toast: ToastrService,
		private helperService: HelperService) { }

	ngOnInit(): void {
		combineLatest([this.buildingBlockHelper.getScopeContainer(),
            this.buildingBlockHelper.getObjectStoreRules()]).pipe(filter(res => res[0] !== null && res[1] !== null)).subscribe(([scopeContainer, rules]) => {
			const container = scopeContainer as Container;
            if(container.id.startsWith('Co') && (container.typeId === containerTypeIds.ProductionRule || container.typeId === containerTypeIds.AIRule)){
                this.selectedRuleVersion = container;
                this.updateVersionAccordionTitle();
                this.ruleVersions = rules.filter(c => c.name === this.selectedRuleVersion?.name);
                this.versionGrid?.grid.instance.refresh();
                const planRecurrenceId = this.buildingBlockHelper.getParentByChildObject(this.selectedRuleVersion).recurrenceId;
                this.periodService.getBoundPeriods().subscribe(periods => {
                    this.everyPeriod = periods.filter(p => p.recurrenceId === planRecurrenceId);
                    this.getVersionData();
                    this.getDistinctPeriods();
                });
            }
		});

		this.versionGridProps = new GridProps('versionGridId', 'Versions', null, true).setKeyColumn('id');
        this.versionGridProps.isGridLabelVisible = true;
        this.versionGridProps.isRowSelectionEnabled = false;
        this.versionGridProps.isFocusedRowEnabled = true;
        this.versionGridProps.rowSelectionCheckBoxesMode = 'always';
        this.versionGridProps.isRowSelectionVisible = false;
        this.versionGridProps.rowSelectionMode = 'single';
        this.versionGridProps.emptyPanelText = 'Version';
        this.versionGridProps.isBulkEditEnabled = false;
        this.versionGridProps.height = 200;

        const versionGridDML =  new DevExpressDMLFunctions(this.versionDataChanged, this, [], true, true, true);
        this.versionGridProps.setGridToScrollingWithDMLForPopup('', false, 'standard', true, versionGridDML, 'batch');
        this.versionGridProps.addOnOptionChangedFunction(this, this.onVersionOptionChanged);
        this.versionGridProps.addOnRowValidatingFunction(this, this.validateDates);
        this.versionGridProps.addOnFocusedRowChangedFunction(this, this.onVersionSelected);
        this.versionGridProps.addOnContentReadyFunction(this, this.onAccordionReady);
        this.versionGridProps.addOnEditorPreparingFunction(this, this.onVersionEditorPreparing);
	}

    versionDataChanged(e: CoreEventArguments): void {
        this.buildingBlockHelper.setShowLoadPanel(true);
        const changes = this.gatherVersionChanges(e.event.changes);

        if (changes.deletes.length === this.ruleVersions.length && !changes.inserts.length) {
            e.event.cancel = true;
            this.buildingBlockHelper.setShowLoadPanel(false);
            this.toast.warning('A rule must have at least one version - deleting a rule entirely must be done from the Plans page');
            return;
        }

        e.event.promise = this.buildingBlockService.batchChangeVersions(changes).toPromise().then(response => {
            if (response.responseCode === 1) {
                const versionPeriodsMap = {};
                const staleVersions: Period[] = [];
                this.ruleVersions.forEach(version => versionPeriodsMap[version.id] = this.periodService.getPeriodsInRange(version.periodBeginId, version.periodEndId, this.everyPeriod));
                response.result.updatedVersions.forEach(updated => {
                    const v = updated;
                    const updatedVersion = new Group(v.id, v.name, v.parentId, v.description, v.typeId, v.objectJson, v.recurrenceId, v.lastModified, v.locked,
                         v.headProcessId, v.isActive, v.periodBeginId, v.periodEndId);
                    this.buildingBlockHelper.replaceObjectInStore(updatedVersion);
                    const newPeriods = this.periodService.getPeriodsInRange(updated.periodBeginId, updated.periodEndId, this.everyPeriod);
                    if(versionPeriodsMap[updated.id].length > newPeriods.length){
                        staleVersions.push(...versionPeriodsMap[updated.id].filter(period => !newPeriods.some(newPeriod => newPeriod.id === period.id)));
                        this.isStalePopupVisible = true;
                    }
                });
                response.result.deletedVersions.forEach(removed => {
                    this.buildingBlockHelper.removeObjectAndChildrenFromObjectStore(removed.id, true);
                    this.isStalePopupVisible = true;
                });
                this.stalePeriodsString = staleVersions.map(period => this.helperService.createDateRangeString(period.beginDate, period.endDate)).join('\n');
                response.result.insertedVersions.forEach(inserted => {
                    this.selectedRuleVersion = inserted as Group;
                    this.buildingBlockHelper.addObjectsToStore([inserted], true);
                    this.updateVersionAccordionTitle();
                });
                if(response.result.copiedObjects.length > 0){
                    this.buildingBlockHelper.addObjectsToStore(response.result.copiedObjects.map(obj => this.buildingBlockService.objToBbObject(obj)), true);
                    this.buildingBlockHelper.refreshDataColumns();
                }
                this.getVersionData();
                if (response.result.insertedVersions.length > 0) {
                    this.buildingBlockHelper.scopeDown(response.result.insertedVersions[0]);
                } else if(response.result.updatedVersions.some(v => v.id === this.selectedRuleVersion.id)) {
                    this.buildingBlockHelper.scopeDown(response.result.updatedVersions.find(v => v.id === this.selectedRuleVersion.id));
                } else if (response.result.deletedVersions.some(v => v.id === this.selectedRuleVersion.id) && this.ruleVersions.length > 0) {
                    this.buildingBlockHelper.scopeDown(this.ruleVersions[0]);
                }
                this.toast.success('Changes saved.');
            } else {
                // Cancel save and re-queue changes.
                e.event.cancel = true;
                this.versionGrid.repaint();
                this.showVersionIssuePopup(response);
            }
            this.buildingBlockHelper.setShowLoadPanel(false);
        });
    }

	onVersionOptionChanged(e: CoreEventArguments): void {
        this.checkForUnsavedChanges();
    }

    onVersionSelected(e: any): void {
        if (this.buildingBlockHelper.hasUnsavedChanges() && e.event.row.key !== this.selectedRuleVersion.id) {
            e.component.grid.focusedRowKey = this.selectedRuleVersion.id;
            this.buildingBlockHelper.notifyUserOfUnsavedChanges();
        } else if (this.selectedRuleVersion !== undefined) {
            if (e.event.row.data?.id && e.event.row.key !== this.selectedRuleVersion.id){
                this.selectedRuleVersion = e.event.row.data;
                this.buildingBlockHelper.scopeDown(this.selectedRuleVersion);
            }
        }
        this.updateVersionAccordionTitle();
    }

    onAccordionReady(e: CoreEventArguments): void {
        if (this.versionGrid !== undefined){
            this.versionGrid.grid.focusedRowKey = this.selectedRuleVersion.id;
        }
    }

	showVersionIssuePopup(response: CoreResponse<RuleVersionAuditResults>): void {
        const versionAuditResults = response.result;
        let issueString = '';
        if (versionAuditResults.versionsWithLockedPeriods.length > 0) {
            issueString += 'Cannot insert or delete the following versions, as they have locked periods in their date range: \n \n';
            for (const versionIdLocked of versionAuditResults.versionsWithLockedPeriods) {
                issueString += versionIdLocked.name + ' - ' + versionIdLocked.description + '\n';
            }
            issueString += '\n';
        }
        if (versionAuditResults.versionsWithOverlaps.length > 0) {
            issueString += 'Overlapping version records detected for: \n \n';
            for (const overlapVersion of versionAuditResults.versionsWithOverlaps) {
                issueString += overlapVersion.name + ' - ' + overlapVersion.description + '\n';
            }
            issueString += '\n';
        }
        if (versionAuditResults.versionsWithMismatchedLockedPeriods.length > 0){
            issueString += 'Cannot edit the following versions, as the change will add or remove a locked period in its date range: \n \n';
            for (const versionIdLocked of versionAuditResults.versionsWithMismatchedLockedPeriods) {
                issueString += versionIdLocked.name + ' - ' + versionIdLocked.description + '\n';
            }
            issueString += '\n';
        }

        this.issueMessage = issueString.length ? issueString : 'Error: ' + response.message;
        this.isIssuePopupVisible = true;
    }

	checkForUnsavedChanges(): boolean {
        const hasUnsavedGridChanges = this.versionGrid.grid.instance.hasEditData();
        if (this.hasUnsavedGridChanges !== hasUnsavedGridChanges) {
            this.hasUnsavedGridChanges = hasUnsavedGridChanges;
            this.versionGridProps.checkboxesDisabled = this.hasUnsavedGridChanges;
            this.versionGrid.grid.instance.refresh();
            return true;
        }
        return false;
    }

	gatherVersionChanges(gridChanges: CoreDataGridChange<Container>[]): RuleVersionBatchChanges {
        const changes = new RuleVersionBatchChanges();
        changes.inserts = [];
        changes.updates = [];
        changes.deletes = [];
        changes.ruleName = this.selectedRuleVersion.name;

        for (const c of gridChanges) {
            if (c.type === devExtremeDMLStrings.Insert) {
                const insertedRow = new Group(
                    '-1',
                    this.selectedRuleVersion.name,
                    this.selectedRuleVersion.parentId.replace('Co', ''),
                    '',
                    this.selectedRuleVersion.typeId,
                    '{"isPayment":false,"isSegment":false,"level":1,"insertXaction":false,"columnMappings":[],"savedColumnBindings":[],"headProcessId":""}',
                    null,
                    'replace this in bb-version-selector',
                    false,
                    '',
                    true,
                    c.data.periodBeginId,
                    c.data.periodEndId
                );

                if (c.data.description){
                    insertedRow.description = c.data.description;
                }
                if (c.data.periodEndId){
                    insertedRow.periodEndId = c.data.periodEndId;
                }

                changes.inserts.push(insertedRow);
            } else if (c.type === devExtremeDMLStrings.Update) {
                const updatedRow = this.helperService.deepCopyTwoPointO(this.ruleVersions.find(version => version.id === c.key));
                updatedRow.id = updatedRow.id.replace('Co', '');
                updatedRow.parentId = updatedRow.parentId.replace('Co', '');

                if (c.data.periodEndId !== undefined){
                    updatedRow.periodEndId = c.data.periodEndId;
                }
                if (c.data.periodBeginId){
                    updatedRow.periodBeginId = c.data.periodBeginId;
                }
                if (c.data.description){
                    updatedRow.description = c.data.description;
                }

                changes.updates.push(updatedRow);
            } else if (c.type === devExtremeDMLStrings.Remove) {
                const removedVersion = this.helperService.deepCopyTwoPointO(this.ruleVersions.find(version => version.id === c.key));
                removedVersion.id = removedVersion.id.replace('Co', '');
                removedVersion.parentId = removedVersion.parentId?.replace('Co', '');
                changes.deletes.push(removedVersion);
            }
        }

        return changes;
    }

	getVersionData(){
        this.versionColumns = [];
        this.versionColumns.push(new CoreColumn('description', 'Description', true, 'string', null, true));
        this.versionColumns.push(new CoreColumn('periodBeginId', 'Start Date', true, 'string', new ColumnType().createLookup(this.everyPeriod, 'id', 'beginDate'),
            true, 'MM-dd-yyyy', null, null, null, null, null, true)
            .addEditorOptions(this.beginDateEditorOptions));
        this.versionColumns.push(new CoreColumn('periodEndId', 'End Date', true, 'string', new ColumnType().createLookup(this.everyPeriod, 'id', 'endDate', false, true),
            true, 'MM-dd-yyyy')
            .addCustomizeText(this, this.replaceNullDates)
            .addEditorOptions(this.endDateEditorOptions));
    }

	replaceNullDates(cellInfo: any): string {
        if (cellInfo.value === null) {
            return 'In Effect';
        }

        return cellInfo.valueText;
    }

    validateDates(e: CoreEventArguments): void  {
        if (e.event.brokenRules.length > 0) {
            e.event.isValid = false;
            return;
        }

        if (e.event.oldData?.beginDate) {
            const beginDate: Date = this.everyPeriod.find(period => e.event.oldData.periodBeginId === period.id).beginDate;
            let endDate: Date;
            if (e.event.newData.periodEndId !== null && e.event.newData.periodEndId !== undefined){
                endDate = this.everyPeriod.find(period => e.event.oldData.periodEndId === period.id).endDate;
            }
            else {
                endDate = null;
            }

            if (endDate === null || endDate === undefined) {
                e.event.isValid = true;
                return;
            }

            e.event.isValid = beginDate < endDate;
            e.event.errorText = 'The End Date must be after the Begin Date, or left blank.';
        } else {
            e.event.isValid = true;
            return;
        }
    }

    getDistinctPeriods(): void {
        this.distinctPeriodBegins = [];
        this.distinctPeriodEnds = [];
        this.everyPeriod.forEach(period => {
            if (!this.distinctPeriodBegins.find(x => x.beginDate === period.beginDate)) {
                this.distinctPeriodBegins.push(period);
            }
            if (!this.distinctPeriodEnds.find(x => x.endDate === period.endDate)) {
                this.distinctPeriodEnds.push(period);
            }
        });
    }

    onVersionEditorPreparing(e: CoreEventArguments): void {
        if (e.event.parentType === 'dataRow' && e.event.dataField === 'periodBeginId') {
            e.event.editorOptions.dataSource = this.distinctPeriodBegins;
        } else if (e.event.parentType === 'dataRow' && e.event.dataField === 'periodEndId') {
            e.event.editorOptions.dataSource = this.distinctPeriodEnds;
        }
    }

    updateVersionAccordionTitle(): void {
        this.versionAccordionTitle = `Versions (${this.selectedRuleVersion.description})`;
    }
}
