
<div *ngIf="dashViewer?.mode !== 'Designer' && hasAdminView || hasPermissionEditPermission" class="content-block admin-view-header">
    <core-dropdown *ngIf="hasAdminView"
        [selectedValue]="dashboardID"
        [dataSource]="dashboards"
        dropdownName="dashboards"
        [props]="dropdownProps"
        (onAdd)="onAddNewDashboard($event)"
        (selectionChanged)="selectDashboard($event)"
        (onDelete)="deleteDashboard()"
        (onRename)="renameDashboard($event)">
    </core-dropdown>
    <div class="header-item-right">
        <dx-button *ngIf="(hasPermissionEditPermission && dashViewer?.mode !== 'Designer')"
            text="Permissions"
            [disabled]="!dashboardID"
            (onClick)="onClickPermissions()">
        </dx-button>
        <dx-button *ngIf="hasAdminView && isHomeDefaultEnabled"
            icon="share"
            text="Home"
            hint="Add to Home Page for Users"
            [disabled]="!dashboardID"
            (onClick)="onClickBulkHomeDefault()">
        </dx-button>
    </div>
</div>

<app-dashboard-viewer
    *ngIf="hasAdminView !== undefined"
    [showLoadingPanel]="true"
    [allowEditing]="hasEditPermission"
    [hasAdminView]="hasAdminView"
    (onListChange)="getDashboards()"
    (onExitDesignerClick)="exitDesignerClick()"
    (onSaveNew)="onSaveNew()">
</app-dashboard-viewer>

<app-attribute-permissions *ngIf="hasPermissionEditPermission"
    [(attributeStr)]="attributePermissionString"
    [(selectedSellerIds)]="publishedSellerIds"
    uniqueId="dashboard-permissions"
    (onSubmit)="onAttributesChanged()"
    [(isPopupVisible)]="isAttributesPopupVisible">
</app-attribute-permissions>
<app-home-default-bulk *ngIf="isHomeDefaultEnabled && hasAdminView"
    [itemId]="dashboardID"
    [type]="dashboardBucketType"
    [(isPopupOpening)]="isBulkHomeDefaultPopupVisible">
</app-home-default-bulk>
<app-nav-bucket-folders *ngIf="hasAdminView"
    [(isPopupVisible)]="isNavBucketFoldersPopupVisible"
    [folderType]="bucketFolderType"
    [itemId]="dashboardID"
    [existingItemMappings]="dashboards">
</app-nav-bucket-folders>
