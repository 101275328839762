import * as Dashboard from 'devexpress-dashboard';
import * as Model from 'devexpress-dashboard/model';
import * as Designer from 'devexpress-dashboard/designer';
import dxButton from 'devextreme/ui/button';
import dxPopup from 'devextreme/ui/popup';
import dxForm, { Properties as dxFormProperties } from 'devextreme/ui/form';
import dxList, { Properties as dxListProperties } from 'devextreme/ui/list';
import dxToolbar from 'devextreme/ui/toolbar';
import DataSource from 'devextreme/data/data_source';

// Model
const chartConstantLinesProperty: Model.CustomPropertyMetadata = {
    ownerType: Model.ChartItem,
    propertyName: 'ConstantLineSettings',
    defaultValue: '[]',
    valueType: 'string'
};

Model.registerCustomProperty(chartConstantLinesProperty);

// Viewer
const onItemWidgetOptionsPrepared = (args) => {
    if (args.dashboardItem instanceof Model.ChartItem) {
        const serializedConstantLines = args.dashboardItem.customProperties.getValue(chartConstantLinesProperty.propertyName);
        const constantLines = JSON.parse(serializedConstantLines);

        const valueAxisOptions = args.options['valueAxis'] || [];
        constantLines.forEach((line) => {
            const axisOptions = valueAxisOptions[0];
            if (axisOptions) {
                let value = line.value;
                axisOptions.constantLines = axisOptions.constantLines || [];
                axisOptions.constantLines.push({
                    value: value, 
                    color: line.color, 
                    dashStyle: 'longDash', 
                    width: 2, 
                    label: { 
                        text: line.labelText
                    }
                });
            }
        });
    }
};

// Designer
// Adds a new section
const onCustomizeSections = (args) => {
    const chartItem = args.dashboardItem;
    if (chartItem instanceof Model.ChartItem) {
        args.addSection({
            title: 'Constant Lines (custom)',
            items: [
                {
                    dataField: chartConstantLinesProperty.propertyName,
                    template: (args, element) => { 
                        const buttonContainer = document.createElement('div');
                        new dxButton(buttonContainer, {
                            text: 'Edit',
                            onClick: () => {
                                showPopup(chartItem)
                            }
                        })
                        return buttonContainer;
                    },
                    label: {
                        visible: false,
                    }
                }
            ]
        });
    }
};

// Generates a pop-up window.
const showPopup = (chartItem) => {
    const popupContainer = document.createElement('div');
    document.body.appendChild(popupContainer);
    const popupOptions = { 
        width : '800px',
        height : 'auto',
        showCloseButton: true,
        hideOnOutsideClick: false,
        contentTemplate: (contentContainer) => {
            const formContainer = document.createElement('div');
            const formOptions = getFormOptions(chartItem);
            contentContainer._form = new dxForm(formContainer, formOptions);
            return formContainer;
        },
        onHidden: () => {
            document.body.removeChild(popupContainer)
        },
        title: 'Constant Lines',
    };
    const popup = new dxPopup(popupContainer, popupOptions);
    popup.show();
}

const getValue = (chartItem) => {
    return JSON.parse(chartItem.customProperties.getValue(chartConstantLinesProperty.propertyName))
}
const setValue = (chartItem, value) => {
    return chartItem.customProperties.setValue(chartConstantLinesProperty.propertyName, JSON.stringify(value))
}

// Creates editors in the pop-up window.
const createListAndToolbar = (form, chartItem) => {
    const element = document.createElement('div');
    const toolbarContainer = document.createElement('div');
    element.appendChild(toolbarContainer);
    const editButton = null;
    let removeButton = null;
    let list = null;

    const toolbarOptions = {
        items: [{
            location: 'before',
            widget: 'dxButton',
            options: {
                icon: 'add',
                stylingMode: 'text',
                onClick: (e) => {
                    const constantLines = getValue(chartItem);
                    const key = constantLines.reduce((acc, item) => { return acc < item.key ? item.key : acc }, 0) + 1
                    const newConstLine = {
                        key: key,
                        name: 'Constant Line' + key,
                        value: 0,
                        color: '#000000',
                        labelText: ''
                    };
                    form.option('formData', newConstLine);

                    const itemInDataSource = constantLines.filter((item) => { return item.key === newConstLine.key} )[0];
                    if(!itemInDataSource) {
                        constantLines.push(newConstLine);
                    } else {
                        const index = constantLines.indexOf(itemInDataSource);
                        constantLines[index] = newConstLine;
                    }

                    setValue(chartItem, constantLines);  
                    list.reload();
                    list.option('selectedItem', constantLines[constantLines.length - 1]);

                },
            }
        },
        {
            location: 'before',
            widget: 'dxButton',
            options: {
                icon: 'remove',
                stylingMode: 'text',
                onInitialized: (e) => { removeButton = e.component },
                onClick: () => {
                    const constantLines = getValue(chartItem);
                    const selectedKey = list.option('selectedItem').key;
                    const index = constantLines.indexOf(constantLines.filter((line) => { return line.key === selectedKey })[0]);
                    if(index >= 0) {
                        constantLines.splice(index, 1);
                        setValue(chartItem, constantLines);
                        list.reload();
                        list.option('selectedItem', constantLines[constantLines.length - 1]);
                    }
                },
            }
        }]
    };

    const updateToolbarState = (hasSelectedItem) => {
        editButton && editButton.option('disabled', !hasSelectedItem);
        removeButton && removeButton.option('disabled', !hasSelectedItem);
    }

    const toolbar = new dxToolbar(toolbarContainer, toolbarOptions);

    const listOptions: dxListProperties = {
        dataSource: new DataSource({ load: () => { return getValue(chartItem)} }),
        displayExpr: 'name',
        height: '200px',
        keyExpr: 'key',
        noDataText: 'Add a Constant Line',
        selectionMode: 'single',
        onContentReady: (e) => { updateToolbarState(!!e.component.option('selectedItem')) },
        onSelectionChanged: (e) => {
            updateToolbarState(!!e.component.option('selectedItem'));
            form.option('formData', e.component.option('selectedItem'));    
        } 
    };

    const listContainer = document.createElement('div');
    listContainer.setAttribute('id', 'ccle-list'); 
    element.appendChild(listContainer);

    list = new dxList(listContainer, listOptions);

    return element;
}

// Gets values for the pop-up window's editors.
const getFormOptions = (chartItem): dxFormProperties => {
    const updateFormState = (form) => {
        let isFormDisabled = true;
        const listInstance = dxList.getInstance(document.querySelector('#ccle-list.dx-list'));
        if (listInstance && listInstance.option('selectedItemKeys')['length'] > 0) {
            isFormDisabled = false;
        }
        
        // Disabling the form items if no list item is selected
        const formGroupItems = form.option('items');
        formGroupItems[1].items.forEach(item => {
            const editor = form.getEditor(item.dataField);
            editor && editor.option('disabled', isFormDisabled);
        });
    };
    return {
        formData: getValue(chartItem)[0] || null,
        colCount: 2,
        items: [
            {
                itemType: 'group',
                template : (args, element) => { return createListAndToolbar(args.component, chartItem) },
            }, 
            {
                itemType: 'group',
                items : [
                    {
                        dataField: 'name',
                        editorType: 'dxTextBox',
                    },
                    {
                        dataField: 'value',
                        editorType: 'dxNumberBox',
                        label: {
                            text: 'Value',
                        },
                        editorOptions: {
                            showSpinButtons: true,
                        }
                    },
                    {
                        dataField: 'color',
                        editorType: 'dxColorBox',
                        label: {
                            text: 'Color',
                        }
                    },
                    {
                        dataField: 'labelText',
                        editorType: 'dxTextBox',
                    }
                ]
            }
        ],
        onContentReady: (e) => { updateFormState(e.component) },
        onFieldDataChanged: (e) => {
            const formData = e.component.option('formData');
            const constantLines = getValue(chartItem);
            const editedConstantLine = constantLines.filter((line) => { return line.key === formData.key })[0];
            constantLines[constantLines.indexOf(editedConstantLine)] = formData;
            setValue(chartItem, constantLines);  
            updateFormState(e.component);
            if (e.dataField === 'name') {
                // Updating the name in the list if name is changed
                const selectedItemTextElement =  document.querySelector('#ccle-list.dx-list .dx-list-item-selected .dx-list-item-content')
                if (selectedItemTextElement) {
                    selectedItemTextElement.innerHTML = e.value;
                }
            }
        },
    };
}

// Event Subscription
export class ChartConstantLinesExtension {
    name = 'ChartConstantLines';

    constructor(private dashboardControl: Dashboard.DashboardControl) {
    }

    start() {
        const viewerApiExtension = this.dashboardControl.findExtension('viewer-api') as Dashboard.ViewerApiExtension;
        if (viewerApiExtension) {
            viewerApiExtension.on('itemWidgetOptionsPrepared', onItemWidgetOptionsPrepared);
        }
        const optionsPanelExtension = this.dashboardControl.findExtension('item-options-panel') as Designer.OptionsPanelExtension;
        if (optionsPanelExtension) {
            optionsPanelExtension.on('customizeSections', onCustomizeSections);
        }
    }
    stop() {
        const viewerApiExtension = this.dashboardControl.findExtension('viewer-api') as Dashboard.ViewerApiExtension;
        if (viewerApiExtension) {
            viewerApiExtension.off('itemWidgetOptionsPrepared', onItemWidgetOptionsPrepared);
        }
        const optionsPanelExtension = this.dashboardControl.findExtension('item-options-panel') as Designer.OptionsPanelExtension;
        if (optionsPanelExtension) {
            optionsPanelExtension.off('customizeSections', onCustomizeSections);
        }
    }
}
