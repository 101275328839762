import { BuildingBlocksSettings } from './building-blocks-settings';
import { BbObject, DatasourceProcess, TransformProcess, FilterProcess, CalculateProcess, AggregateProcess, Group, Gear, UnionProcess, AIProcess } from '../../shared/models/building-blocks';
import { EnumContainerType } from 'src/app/shared/constants/enums';

// HACK: Using a behavior flyweight because DxDiagramComponent doesn't recognize abstract node members. -DH
export interface NodeBehavior {
    getDescription(process: BbObject): string,
    getShapeCode(process: BbObject): string
}

export abstract class TypedNodeBehavior<TProcess extends BbObject> implements NodeBehavior {
    getDescription(process: BbObject): string {
        return this.typedGetDescription(process as TProcess);
    }

    getShapeCode(process: BbObject): string {
        return this.typedGetShapeCode(process as TProcess);
    }

    abstract typedGetDescription(process: TProcess): string;
    abstract typedGetShapeCode(process: TProcess): string;
}

export class DatasourceProcessNodeBehavior extends TypedNodeBehavior<DatasourceProcess> {
    private static _instance: DatasourceProcessNodeBehavior = null;

    static get instance(): DatasourceProcessNodeBehavior {
        if (this._instance === null) {
            this._instance = new DatasourceProcessNodeBehavior();
        }
        return this._instance;
    }

    private constructor() {
        super();
    }

    typedGetDescription(process: DatasourceProcess): string {
        return '';
    }

    typedGetShapeCode(process: DatasourceProcess): string {
        return 'Core.DatasourceProcess';
    }
}

export class TransformProcessNodeBehavior extends TypedNodeBehavior<TransformProcess> {
    private static _instance: TransformProcessNodeBehavior = null;

    static get instance(): TransformProcessNodeBehavior {
        if (this._instance === null) {
            this._instance = new TransformProcessNodeBehavior();
        }
        return this._instance;
    }

    private constructor() {
        super();
    }

    typedGetDescription(process: TransformProcess): string {
        return '';
    }

    typedGetShapeCode(process: TransformProcess): string {
        return 'Core.TransformProcess';
    }
}

export class FilterProcessNodeBehavior extends TypedNodeBehavior<FilterProcess> {
    private static _instance: FilterProcessNodeBehavior = null;

    static get instance(): FilterProcessNodeBehavior {
        if (this._instance === null) {
            this._instance = new FilterProcessNodeBehavior();
        }
        return this._instance;
    }

    private constructor() {
        super();
    }

    typedGetDescription(process: FilterProcess): string {
        return process.filterString;
    }

    typedGetShapeCode(process: FilterProcess): string {
        return 'Core.FilterProcess';
    }
}

export class CalculateProcessNodeBehavior extends TypedNodeBehavior<CalculateProcess> {
    private static _instance: CalculateProcessNodeBehavior = null;

    static get instance(): CalculateProcessNodeBehavior {
        if (this._instance === null) {
            this._instance = new CalculateProcessNodeBehavior();
        }
        return this._instance;
    }

    private constructor() {
        super();
    }

    typedGetDescription(process: CalculateProcess): string {
        return process.displayFormulaString;
    }

    typedGetShapeCode(process: CalculateProcess): string {
        return 'Core.CalculateProcess';
    }
}

export class AggregateProcessNodeBehavior extends TypedNodeBehavior<AggregateProcess> {
    private static _instance: AggregateProcessNodeBehavior = null;

    static get instance(): AggregateProcessNodeBehavior {
        if (this._instance === null) {
            this._instance = new AggregateProcessNodeBehavior();
        }
        return this._instance;
    }

    private constructor() {
        super();
    }

    typedGetDescription(process: AggregateProcess): string {
        return '';
    }

    typedGetShapeCode(process: AggregateProcess): string {
        return 'Core.AggregateProcess';
    }
}

export class UnionProcessNodeBehavior extends TypedNodeBehavior<UnionProcess> {
    private static _instance: UnionProcessNodeBehavior = null;

    static get instance(): UnionProcessNodeBehavior {
        if (this._instance === null) {
            this._instance = new UnionProcessNodeBehavior();
        }
        return this._instance;
    }

    private constructor() {
        super();
    }

    typedGetDescription(process: UnionProcess): string {
        return '';
    }

    typedGetShapeCode(process: UnionProcess): string {
        return 'Core.UnionProcess';
    }
}

export class GroupNodeBehavior extends TypedNodeBehavior<Group> {
    private static _instance: GroupNodeBehavior = null;

    static get instance(): GroupNodeBehavior {
        if (this._instance === null) {
            this._instance = new GroupNodeBehavior();
        }
        return this._instance;
    }

    private constructor() {
        super();
    }

    typedGetDescription(process: Group): string {
        return process.description;
    }

    typedGetShapeCode(process: Group): string {
        return process.typeId === EnumContainerType.Group ? 'Core.Gearbox' : 'Core.Group';
    }
}

export class GroupContainerNodeBehavior extends TypedNodeBehavior<Group> {
    private static _instance: GroupNodeBehavior = null;

    static get instance(): GroupNodeBehavior {
        if (this._instance === null) {
            this._instance = new GroupContainerNodeBehavior();
        }
        return this._instance;
    }

    private constructor() {
        super();
    }

    typedGetDescription(process: Group): string {
        return process.description;
    }

    typedGetShapeCode(process: Group): string {
        return 'verticalContainer';
    }
}

export class GearNodeBehavior extends TypedNodeBehavior<Gear> {
    private static _instance: GearNodeBehavior = null;

    static get instance(): GearNodeBehavior {
        if (this._instance === null) {
            this._instance = new GearNodeBehavior();
        }
        return this._instance;
    }

    private constructor() {
        super();
    }

    typedGetDescription(process: Gear): string {
        return process.description;
    }

    typedGetShapeCode(process: Gear): string {
        return 'Core.Gear';
    }
}

export class PeriodFilterNodeBehaviour extends TypedNodeBehavior<Gear> {
    private static _instance: PeriodFilterNodeBehaviour = null;

    static get instance(): PeriodFilterNodeBehaviour {
        if (this._instance === null) {
            this._instance = new PeriodFilterNodeBehaviour();
        }
        return this._instance;
    }

    private constructor() {
        super();
    }

    typedGetDescription(process: Gear): string {
        return process.description;
    }

    typedGetShapeCode(process: Gear): string {
        return 'Core.PeriodGear.Gear';
    }
}

export class AssignNodeBehaviour extends TypedNodeBehavior<Gear> {
    private static _instance: AssignNodeBehaviour = null;

    static get instance(): AssignNodeBehaviour {
        if (this._instance === null) {
            this._instance = new AssignNodeBehaviour();
        }
        return this._instance;
    }

    private constructor() {
        super();
    }

    typedGetDescription(process: Gear): string {
        return process.description;
    }

    typedGetShapeCode(process: Gear): string {
        return 'Core.Assign.Gear';
    }
}

export class FilterNodeBehaviour extends TypedNodeBehavior<Gear> {
    private static _instance: FilterNodeBehaviour = null;

    static get instance(): FilterNodeBehaviour {
        if (this._instance === null) {
            this._instance = new FilterNodeBehaviour();
        }
        return this._instance;
    }

    private constructor() {
        super();
    }

    typedGetDescription(process: Gear): string {
        return process.description;
    }

    typedGetShapeCode(process: Gear): string {
        return 'Core.Filter.Gear';
    }
}

export class EarnDateNodeBehaviour extends TypedNodeBehavior<Gear> {
    private static _instance: EarnDateNodeBehaviour = null;

    static get instance(): EarnDateNodeBehaviour {
        if (this._instance === null) {
            this._instance = new EarnDateNodeBehaviour();
        }
        return this._instance;
    }

    private constructor() {
        super();
    }

    typedGetDescription(process: Gear): string {
        return process.description;
    }

    typedGetShapeCode(process: Gear): string {
        return 'Core.EarnDate.Gear';
    }
}

export class AccountNormalizeNodeBehaviour extends TypedNodeBehavior<Gear> {
    private static _instance: AccountNormalizeNodeBehaviour = null;

    static get instance(): AccountNormalizeNodeBehaviour {
        if (this._instance === null) {
            this._instance = new AccountNormalizeNodeBehaviour();
        }
        return this._instance;
    }

    private constructor() {
        super();
    }

    typedGetDescription(process: Gear): string {
        return process.description;
    }

    typedGetShapeCode(process: Gear): string {
        return 'Core.AccountNormalize.Gear';
    }
}

export class CounterNodeBehaviour extends TypedNodeBehavior<Gear> {
    private static _instance: CounterNodeBehaviour = null;

    static get instance(): CounterNodeBehaviour {
        if (this._instance === null) {
            this._instance = new CounterNodeBehaviour();
        }
        return this._instance;
    }

    private constructor() {
        super();
    }

    typedGetDescription(process: Gear): string {
        return process.description;
    }

    typedGetShapeCode(process: Gear): string {
        return 'Core.Counter.Gear';
    }
}

export class ContextNodeBehaviour extends TypedNodeBehavior<Gear> {
    private static _instance: ContextNodeBehaviour = null;

    static get instance(): ContextNodeBehaviour {
        if (this._instance === null) {
            this._instance = new ContextNodeBehaviour();
        }
        return this._instance;
    }

    private constructor() {
        super();
    }

    typedGetDescription(process: Gear): string {
        return process.description;
    }

    typedGetShapeCode(process: Gear): string {
        return 'Core.Datasource.Gear';
    }
}

export class FormulaNodeBehaviour extends TypedNodeBehavior<Gear> {
    private static _instance: FormulaNodeBehaviour = null;

    static get instance(): FormulaNodeBehaviour {
        if (this._instance === null) {
            this._instance = new FormulaNodeBehaviour();
        }
        return this._instance;
    }

    private constructor() {
        super();
    }

    typedGetDescription(process: Gear): string {
        return process.description;
    }

    typedGetShapeCode(process: Gear): string {
        return 'Core.Formula.Gear';
    }
}

export class ConsolidateNodeBehaviour extends TypedNodeBehavior<Gear> {
    private static _instance: ConsolidateNodeBehaviour = null;

    static get instance(): ConsolidateNodeBehaviour {
        if (this._instance === null) {
            this._instance = new ConsolidateNodeBehaviour();
        }
        return this._instance;
    }

    private constructor() {
        super();
    }

    typedGetDescription(process: Gear): string {
        return process.description;
    }

    typedGetShapeCode(process: Gear): string {
        return 'Core.Consolidate.Gear';
    }
}

export class HierarchyNodeBehaviour extends TypedNodeBehavior<Gear> {
    private static _instance: HierarchyNodeBehaviour = null;

    static get instance(): HierarchyNodeBehaviour {
        if (this._instance === null) {
            this._instance = new HierarchyNodeBehaviour();
        }
        return this._instance;
    }

    private constructor() {
        super();
    }

    typedGetDescription(process: Gear): string {
        return process.description;
    }

    typedGetShapeCode(process: Gear): string {
        return 'Core.Hierarchy.Gear';
    }
}

export class JoinNodeBehaviour extends TypedNodeBehavior<Gear> {
    private static _instance: JoinNodeBehaviour = null;

    static get instance(): JoinNodeBehaviour {
        if (this._instance === null) {
            this._instance = new JoinNodeBehaviour();
        }
        return this._instance;
    }

    private constructor() {
        super();
    }

    typedGetDescription(process: Gear): string {
        return process.description;
    }

    typedGetShapeCode(process: Gear): string {
        return 'Core.Join.Gear';
    }
}

export class UnionNodeBehaviour extends TypedNodeBehavior<Gear> {
    private static _instance: UnionNodeBehaviour = null;

    static get instance(): UnionNodeBehaviour {
        if (this._instance === null) {
            this._instance = new UnionNodeBehaviour();
        }
        return this._instance;
    }

    private constructor() {
        super();
    }

    typedGetDescription(process: Gear): string {
        return process.description;
    }

    typedGetShapeCode(process: Gear): string {
        return 'Core.Union.Gear';
    }
}

export class UpdateXactionNodeBehaviour extends TypedNodeBehavior<Gear> {
    private static _instance: UpdateXactionNodeBehaviour = null;

    static get instance(): UpdateXactionNodeBehaviour {
        if (this._instance === null) {
            this._instance = new UpdateXactionNodeBehaviour();
        }
        return this._instance;
    }

    private constructor() {
        super();
    }

    typedGetDescription(process: Gear): string {
        return process.description;
    }

    typedGetShapeCode(process: Gear): string {
        return 'Core.Update.Gear';
    }
}

export class AIFilterNodeBehaviour extends TypedNodeBehavior<Gear> {
    private static _instance: AIFilterNodeBehaviour = null;

    static get instance(): AIFilterNodeBehaviour {
        if (this._instance === null) {
            this._instance = new AIFilterNodeBehaviour();
        }
        return this._instance;
    }

    private constructor() {
        super();
    }

    typedGetDescription(process: Gear): string {
        return process.description;
    }

    typedGetShapeCode(process: Gear): string {
        return 'Core.AIFilter.AIGear';
    }
}

export class ScoreGearNodeBehaviour extends TypedNodeBehavior<Gear> {
    private static _instance: ScoreGearNodeBehaviour = null;

    static get instance(): ScoreGearNodeBehaviour {
        if (this._instance === null) {
            this._instance = new ScoreGearNodeBehaviour();
        }
        return this._instance;
    }

    private constructor() {
        super();
    }

    typedGetDescription(process: Gear): string {
        return process.description;
    }

    typedGetShapeCode(process: Gear): string {
        return 'Core.Score.AIGear';
    }
}

export class AIContextNodeBehaviour extends TypedNodeBehavior<Gear> {
    private static _instance: AIContextNodeBehaviour = null;

    static get instance(): AIContextNodeBehaviour {
        if (this._instance === null) {
            this._instance = new AIContextNodeBehaviour();
        }
        return this._instance;
    }

    private constructor() {
        super();
    }

    typedGetDescription(process: Gear): string {
        return process.description;
    }

    typedGetShapeCode(process: Gear): string {
        return 'Core.AIContext.AIGear';
    }
}

export class TextGenerationNodeBehaviour extends TypedNodeBehavior<Gear> {
    private static _instance: TextGenerationNodeBehaviour = null;

    static get instance(): TextGenerationNodeBehaviour {
        if (this._instance === null) {
            this._instance = new TextGenerationNodeBehaviour();
        }
        return this._instance;
    }

    private constructor() {
        super();
    }

    typedGetDescription(process: Gear): string {
        return process.description;
    }

    typedGetShapeCode(process: Gear): string {
        return 'Core.TextGeneration.AIGear';
    }
}

export class RAGNodeBehaviour extends TypedNodeBehavior<Gear> {
    private static _instance: RAGNodeBehaviour = null;

    static get instance(): RAGNodeBehaviour {
        if (this._instance === null) {
            this._instance = new RAGNodeBehaviour();
        }
        return this._instance;
    }

    private constructor() {
        super();
    }

    typedGetDescription(process: Gear): string {
        return process.description;
    }

    typedGetShapeCode(process: Gear): string {
        return 'Core.FileRetrieval.AIGear';
    }
}

export class SummaryNodeBehaviour extends TypedNodeBehavior<Gear> {
    private static _instance: SummaryNodeBehaviour = null;

    static get instance(): SummaryNodeBehaviour {
        if (this._instance === null) {
            this._instance = new SummaryNodeBehaviour();
        }
        return this._instance;
    }

    private constructor() {
        super();
    }

    typedGetDescription(process: Gear): string {
        return process.description;
    }

    typedGetShapeCode(process: Gear): string {
        return 'Core.Summary.AIGear';
    }
}

export class AIClassifierNodeBehavior extends TypedNodeBehavior<Gear>{
    private static _instance: AIClassifierNodeBehavior = null;

    static get instance(): AIClassifierNodeBehavior {
        if (this._instance === null) {
            this._instance = new AIClassifierNodeBehavior();
        }
        return this._instance;
    }

    private constructor() {
        super();
    }

    typedGetDescription(process: Gear): string {
        return process.description;
    }

    typedGetShapeCode(process: Gear): string {
        return 'Core.AIClassifier.AIGear';
    }
}

export class AIProcessNodeBehavior extends TypedNodeBehavior<AIProcess> {
    private static _instance: AIProcessNodeBehavior = null;

    static get instance(): AIProcessNodeBehavior {
        if (this._instance === null) {
            this._instance = new AIProcessNodeBehavior();
        }
        return this._instance;
    }

    private constructor() {
        super();
    }

    typedGetDescription(process: AIProcess): string {
        return '';
    }

    typedGetShapeCode(process: AIProcess): string {
        return 'Core.AIProcess';
    }
}

export class FileSourceNodeBehavior extends TypedNodeBehavior<AIProcess> {
    private static _instance: FileSourceNodeBehavior = null;

    static get instance(): FileSourceNodeBehavior {
        if (this._instance === null) {
            this._instance = new FileSourceNodeBehavior();
        }
        return this._instance;
    }

    private constructor() {
        super();
    }

    typedGetDescription(process: AIProcess): string {
        return 'File Source';
    }

    typedGetShapeCode(process: AIProcess): string {
        return 'Core.FileSource';
    }
}

export class Node {
    readonly id: string;
    readonly path: string;
    readonly obj: BbObject;
    readonly behavior: NodeBehavior;
    readonly parentId: string;
    isDuplicate: boolean = false;
    isForeign: boolean = false;
    isLowOpacity: boolean = false;
    isLocked: boolean = false;
    isHead: boolean = false;
    shapeCode: string;
    text: string;
    description: string;
    tags: any[];
    minWidth: number;

    constructor(pathingNode: Node, obj: BbObject, isPathed: boolean, behavior: NodeBehavior, parentId = null) {
        this.obj = obj;
        this.behavior = behavior;
        this.path = Node.getPath(pathingNode, obj.id);
        this.id = Node.getId(pathingNode, obj.id, isPathed);
        this.parentId = parentId;
        this.shapeCode = behavior.getShapeCode(obj);
        this.description = behavior.getDescription(obj);
        this.text = Node.getText(this);
        this.tags = [];
    }

    get name(): string {
        return this.obj.name;
    }

    static getText(node: Node): string {
        let result = '';
        if (BuildingBlocksSettings.debugMode) {
            result += node.id + '\n';
        }
        result += node.obj.name;
        return result;
    }

    static getId(pathingNode: Node, objectId: string, isPathed: boolean): string {
        return isPathed ? Node.getPath(pathingNode, objectId) : objectId;
    }

    static getPath(pathingNode: Node, objectId: string): string {
        let result = '';
        if (pathingNode !== null) {
            result += `${pathingNode.path}.`;
        }
        result += objectId;
        return result;
    }
}
