import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CoreRequest } from '../models/core-request';
import { CoreResponse } from '../models/core-response';
import { HistSeller } from '../models/hist-seller';
import { HistSellerBatchChanges } from '../models/hist-seller-batch-changes';
import { HistSellerCreationInfo } from '../models/hist-seller-creation-info';
import { HistSellerAuditResults } from '../models/hist-seller-audit-results';

@Injectable()
export class HistSellerService {
    importURL: string;
    private baseurl: string = environment.apiEndpoint;
    private histSellersURL = this.baseurl + '/histseller/';

    constructor(private http: HttpClient) {
        this.importURL = `${this.histSellersURL}import`;
    }

    getMultipleHistSellers(sellerIds: number[]): Observable<HistSeller[]> {
        return this.http.post<HistSeller[]>(this.histSellersURL + 'getmultiple', sellerIds);
    }

    batchChangeHistSellers(changes: HistSellerBatchChanges): Observable<CoreResponse<HistSellerAuditResults>> {
        return this.http.post<CoreResponse<HistSellerAuditResults>>(this.histSellersURL + 'batchchanges', changes);
    }

    getCurrentHistSeller(histSellers: HistSeller[]): HistSeller {
        return histSellers.find(hist => new Date(hist.beginDate) < new Date() && (hist.endDate === null || new Date(hist.endDate) > new Date()));
    }

    getCheckManagers(): Observable<CoreResponse<HistSellerAuditResults>> {
        return this.http.get<CoreResponse<HistSellerAuditResults>>(this.histSellersURL + 'checkmanagersvalid');
    }
}
