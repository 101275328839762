import { AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation, HostListener } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ReportViewerComponent } from '../../shared/components/report-viewer/report-viewer.component';
import { ReportDesignerComponent } from '../../shared/components/report-designer/report-designer.component';
import { CoreBotInteractionTriggerType, CoreFeature, EnumBucketClass, EnumBucketType, EnumCoreBotInteraction, EnumUserGroup, coreResponseCodes } from '../../shared/constants/enums';
import { Bucket } from '../../shared/models/bucket';
import { BucketService } from '../../shared/services/bucket.service';
import { PeriodService } from '../../shared/services/period.service';
import { ReportService } from '../../shared/services/report.service';
import { SellerService } from '../../shared/services/seller.service';
import { EmailContext } from '../../shared/models/contexts/email-context';
import { SendEmailResult } from '../../shared/models/send-email-result';
import { SettingService } from '../../shared/services/setting.service';
import { EmailService } from '../../shared/services/email.service';
import { EnumSettingClassId } from '../../shared/constants/enums';
import { confirm } from 'devextreme/ui/dialog';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from 'src/app/shared/services/helper.service';
import { EmailLayout } from 'src/app/shared/models/email-layout';
import { Seller } from 'src/app/shared/models/seller';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CoreDropdownProperties } from 'src/app/shared/models/core-dropdown-properties';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { CoreDropdownComponent } from 'src/app/shared/components/core-dropdown/core-dropdown.component';
import { ReportLayout } from 'src/app/shared/models/report-layout';
import { ReportLayoutContext } from 'src/app/shared/models/contexts/report-layout-context';
import { CanDeactivateComponent } from 'src/app/shared/interfaces/can-deactivate-component';
import { AppElementsService } from 'src/app/shared/services/app-element.service';
import { CanDeactivateService } from 'src/app/shared/services/can-deactivate.service';
import { report } from 'process';
import { UpdatePublishRequest } from 'src/app/shared/models/update-publish-request';
import { CoreBotInteractionService } from 'src/app/shared/services/core-bot-interaction-service';
import { AppInfoService } from 'src/app/shared/services/app-info.service';
import { XactionService } from 'src/app/shared/services/xaction.service';
import { XactionCountRequest } from 'src/app/shared/models/xaction-count-request';

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./reports.component.scss',
        '../../../../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.common.css',
        '../../../../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.light.css',
        '../../../../node_modules/devexpress-reporting/dist/css/dx-webdocumentviewer.css',
        '../../../../node_modules/ace-builds/css/ace.css',
        '../../../../node_modules/ace-builds/css/theme/dreamweaver.css',
        '../../../../node_modules/ace-builds/css/theme/ambiance.css'],
    providers: [PeriodService, SellerService, EmailService, HelperService]
})

export class ReportsComponent implements AfterViewInit, CanDeactivateComponent {
    @ViewChildren('viewer') reportViewerList: QueryList<ReportViewerComponent>;
    @ViewChild(CoreDropdownComponent) dropdown: CoreDropdownComponent;
    @ViewChild(ReportDesignerComponent, { static: true })
    reportDesigner: ReportDesignerComponent;

    username: string;
    reportViewer: ReportViewerComponent;
    reportUrl: string;
    reportName: string;
    reportID: number;
    pullDown: boolean = false;
    loadingVisible: boolean = false;
    useDefaultBucket: boolean = false;
    favoriteBucket: Bucket;
    isNavBucketFoldersPopupVisible: boolean = false;
    bucketFolderType: EnumBucketType = EnumBucketType.Report;
    isDesigner: boolean = false;
    isDesignButtonVisible: boolean = false;

    emailEnabled: boolean = false;
    emailPopupVisible: boolean = false;
    includeSubs: string[] = [
        'Include Account Only',
        'Include Direct Subordinates Only',
        'Include All Subordinates'];
    includeSubsHints: string[] = [
        'Include only the recipient\'s data on the report',
        'Include only the recipient\'s and their direct subordinates\' data on the report',
        'Include only the recipient\'s and all of their subordinates\' data on the report'
    ];
    emailForm = {to: '', 'viewable accounts': this.includeSubs[0], subject: '', body: '', attachmentName: '', dateRange: '', sendZeroes: false};
    emailLayout: EmailLayout;
    selectedSellersCount: number;
    selectedSellersOptions: string[];
    sendEmailResults: SendEmailResult[];
    sellerMapping: any;
    sellers: Seller[];
    isImplementer: boolean;
    publishedSellerIds: number[] = [];

    sellerId: number;
    hasAdminView: boolean = false;
    reports: {id: number, name: string}[] = [];
    favoriteButton: any = {
        id: 'favorite-button',
        icon: 'favorites',
        hint: 'Favorite/Unfavorite',
        onClick: () => {
            if (this.favoriteBucket) {
                this.bucketService.deleteFavoriteBucket(this.favoriteBucket).subscribe();
            } else {
                this.bucketService.addFavoriteBucket(this.reportID, EnumBucketType.Report, this.sellerId).subscribe();
            }
        }
    };
    navFolderButton: any = {
        id: 'nav-folder-settings',
        icon: 'folder',
        hint: 'Nav Folder Settings',
        onClick: () => {
            this.isNavBucketFoldersPopupVisible = true;
        }
    };

    permissionCreateReport: boolean;
    permissionEditReport: boolean;
    permissionDeleteReport: boolean;
    permissionEditReportPermissions: boolean;
    isAttributesPopupVisible: boolean = false;
    dropdownProps = new CoreDropdownProperties();
    path = 'pages/report';
    isUsingCoreBotTools: boolean = false;
    xactionCountRequest: XactionCountRequest;

    attributePermissionString: string = '';
    isHomeDefaultEnabled: boolean;
    isBulkHomeDefaultPopupVisible: boolean = false;
    readonly reportBucketType = EnumBucketType.Report;

    // these next two properties are used for a leave confirmation message pop-up by CanDeactivateService
    canDeactivateMessage = 'Are you sure you want to leave Report Designer? Unsaved changes will be lost.';
    canDeactivateMessageTitle = 'Report Designer';

    constructor(private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private reportService: ReportService,
        private bucketService: BucketService,
        private settingService: SettingService,
        private emailService: EmailService,
        private toast: ToastrService,
        private sellerService: SellerService,
        private helperService: HelperService,
        private appElementsService: AppElementsService,
        private authService: AuthService,
        private canDeactivateService: CanDeactivateService,
        private coreBotInteractionService: CoreBotInteractionService,
        private appInfo: AppInfoService,
        private xactionService: XactionService,
        private permissionService: PermissionService) {
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeUnload(event: Event): void {
        if (this.reportDesigner.hasUnsavedChanges()) {
            // the following is in place to handle legacy cases. See mdn for clarification
            // eslint-disable-next-line deprecation/deprecation
            event.returnValue = true;
        }
    }

    async ngAfterViewInit() {
        this.sellerId = this.authService.getSellerIdFromToken();
        this.username = this.authService.getUserFromToken();
        this.permissionCreateReport = this.permissionService.checkCurrentUserPermission(CoreFeature.CreateReports.toString());
        this.permissionEditReport = this.permissionService.checkCurrentUserPermission(CoreFeature.EditReports.toString());
        this.permissionDeleteReport = this.permissionService.checkCurrentUserPermission(CoreFeature.DeleteReports.toString());
        this.permissionEditReportPermissions = this.permissionService.checkCurrentUserPermission(CoreFeature.AssignReportPermissions.toString());

        this.dropdownProps.createAll(true, 'id', 'name',!this.permissionEditReport,!this.permissionCreateReport
                                        ,!this.permissionEditReport, !this.permissionDeleteReport, false, false, 250, 'report')
            .setProp('searchEnabled', true);
        this.dropdownProps.saveHidden = true;
        this.dropdownProps.dynamicButtons.push(this.favoriteButton);

        this.settingService.getBoolSetting(EnumSettingClassId.EnableNavBucketFolders).subscribe(navBucketFoldersEnabled => {
            if (navBucketFoldersEnabled) {
                this.dropdownProps.dynamicButtons.push(this.navFolderButton);
            }
        });

        await Promise.all([
            this.permissionService.getIsImplementer().toPromise(),
            this.sellerService.getHasAdminView().toPromise(),
            this.bucketService.useDefaultBucket().toPromise(),
            this.settingService.getBoolSetting(EnumSettingClassId.WebEmailReportButton).toPromise(),
            this.settingService.getBoolSetting(EnumSettingClassId.CoreBotBetaTesting).toPromise()
        ]).then(([isImplementer, hasAdminView, useDefaultBucket, emailEnabled, isUsingCoreBotTools]) => {
            this.isImplementer = isImplementer;
            this.hasAdminView = hasAdminView;
            this.useDefaultBucket = useDefaultBucket;
            this.emailEnabled = emailEnabled;
            this.isUsingCoreBotTools = isUsingCoreBotTools;
        });

        this.bucketService.useDefaultBucket().subscribe(result => this.useDefaultBucket = result);
        this.reportViewerList.changes.subscribe((res: QueryList<ReportViewerComponent>) => {
            this.reportViewer = res.first;
            this.reportViewer?.setDesignButtonVisibility(this.isDesignButtonVisible);
            if (!this.hasAdminView) {
                this.reportViewer.generateReport(this.reportID);
            }
        });
        if (this.hasAdminView) {
            this.reportService.getReportMapping().subscribe(reports => this.reports = reports.sort((a, b) => a.name.localeCompare(b.name)));
        }
        this.route.params.subscribe(params => {
            let reportID;
            let isBlankReport = false;
            if (params['reportID']) {
                reportID = parseInt(params['reportID'], 10);
            } else {
                reportID = parseInt(sessionStorage.getItem('selectedReport'), 10);
            }
            if (reportID) {
                this.reportService.getReport(reportID).subscribe(rep => {
                    this.reportName = rep.name;
                });
                this.reportService.getReportSellerPublishByReportParId(reportID).subscribe(result => {
                    for (const publishedSeller of result)
                    {
                        this.publishedSellerIds.push(publishedSeller.sellerId);
                    }
                });
                setTimeout(() => this.onReportSelected(reportID));
                this.isDesignButtonVisible = true;
            } else {
                // Changing this to true would display the report designer automatically when the user first navigates to the reports page
                //   kind of like how CoreWin does when 'Report Designer' button is clicked
                this.isDesignButtonVisible = false;
                isBlankReport = false;
            }

            if (isBlankReport) {
                this.isDesigner = true;
                this.reportName = 'Report Designer';
                this.reportDesigner.createReport();
            }
        });

        if (this.emailEnabled) {
            this.emailService.getEmailLayoutByName('<Report Email>').subscribe(emailLayout => {
                this.emailLayout = emailLayout;
            });
            this.sellerService.getSellerMapping().subscribe(result => this.sellerMapping = result);
            this.sellerService.getSubordinatesWithCurrentHistory(this.sellerId, true).subscribe(result => this.sellers = result);
        }
        this.bucketService.getFavoriteEvent().subscribe(() => {
            this.getFavoriteBucket();
        });
    }

    canDeactivate(): boolean {
        return !this.reportDesigner.hasUnsavedChanges();
    }

    handleDeactivationResult(result: boolean): void {
        if (result) {
            this.reportDesigner.discardReportChanges();

            // Check if a logout attempt triggered the popup - in which case we now want to log out
            if (this.appElementsService.headerComponent.awaitingLogout) {
                this.appElementsService.headerComponent.unsavedChanges = false;
                this.appElementsService.headerComponent.onLogout();
            }
        }
    }

    refreshViewer() {
        this.reportViewer.generateReport(this.reportID);
    }

    onEmailButtonClick() {
        this.getReportParams();
        this.emailForm.subject = this.emailLayout.emailSubject;
        this.emailForm.body = this.emailLayout.emailContent;
        this.emailForm.attachmentName = this.emailLayout.emailAttachmentName;
        this.emailPopupVisible = true;
    }

    getReportParams() {
        const sellerIds: string[] = this.helperService.removeBracketsFromString(localStorage.getItem(`${this.username}.selectedSellers`), true) as string[];
        this.selectedSellersCount = sellerIds.length;
        this.emailForm.to = sellerIds.map(id => this.sellerMapping[+id] ?? 'Core Admin').sort().join('; ') + (this.selectedSellersCount ? ';' : '');
        this.emailForm.dateRange = this.helperService.createDateRangeString(new Date(localStorage.beginDate), new Date(localStorage.endDate));
        this.setXactionCountRequest();
    }

    onSendClick() {
        const sellers = (this.helperService.removeBracketsFromString(localStorage.getItem(`${this.username}.selectedSellers`), true) as string[]).map(id => this.sellers.find(s => s.id === +id));
        sellers.sort((a, b) => a.name.localeCompare(b.name));

        let msg = `Are you sure you want to send this report to the selected recipients?<div id="confirm-recipients"${sellers.length > 25 ? ' class="scrollable"' : ''}>`;
        sellers.forEach(seller => {
            msg += `<div class="row"><div class="col">${seller.name}</div><div class="col">${(seller.emailAddress ?? '')}</div></div>`;
        });

        confirm(msg, 'Confirm').then(response => response ? this.sendEmail() : null);
    }

    sendEmail() {
        const includeSubsCode = this.includeSubs.indexOf(this.emailForm['viewable accounts']);
        const selectedSellers = localStorage.getItem(`${this.username}.selectedSellers`);
        const emailContext = new EmailContext(selectedSellers, this.emailForm.subject, this.emailForm.body, null, this.reportID,
            new Date(localStorage.beginDate), new Date(localStorage.endDate), includeSubsCode, this.emailForm.attachmentName, this.emailForm.sendZeroes);

        this.emailPopupVisible = false;
        this.loadingVisible = true;
        this.emailService.sendEmail(emailContext).subscribe(result => {
                this.loadingVisible = false;
                this.sendEmailResults = result;
            }, error => {
                this.loadingVisible = false;
                this.toast.error(error.error);
            }
        );
    }

    onReportSelected(id) {
        if (!id || id === this.reportID) {
            return;
        }

        this.reportService.getReport(id).subscribe(result => this.attributePermissionString = result.attributes);
        this.publishedSellerIds = [];
        this.reportService.getReportSellerPublishByReportParId(id).subscribe(result => {
            for (const publishedSeller of result)
            {
                this.publishedSellerIds.push(publishedSeller.sellerId);
            }
        });
        this.reportViewer?.setDesignButtonVisibility(true);
        this.reportName = this.reports.find(x => x.id === id)?.name;
        if (this.isDesigner) {
            if (this.reportDesigner.hasUnsavedChanges()) {
                const promise = this.canDeactivateService.canDeactivate(this.canDeactivateMessage, this.canDeactivateMessageTitle);
                promise.then(result => {
                    this.handleDeactivationResult(result);
                    if (result) {
                        this.loadSelectedReport(id);
                    } else {
                        setTimeout(() => {
                            this.dropdown.selectedValue = this.reportID;
                        }, 10);
                    }
                });
            } else {
                this.loadSelectedReport(id);
            }
        } else {
            this.loadSelectedReport(id);
        }
    }

    reportNameChanged(newName: string): void {
        this.reportName = newName;
    }

    loadSelectedReport(id) {
        this.loadingVisible = true;
        if (id && this.reportID !== id) {
            this.reportID = id;
            if (this.reportDesigner && this.isDesigner) {
                this.reportDesigner.editReport(this.reportID);
            } else if (this.reportViewer && !this.isDesigner) {
                this.reportViewer.generateReport(this.reportID);
            }
            this.getFavoriteBucket();

            sessionStorage.setItem('selectedReport', id);
        }
        this.location.go(this.path + (id ? '/' + id : ''));
    }

    getFavoriteBucket() {
        this.bucketService.getFavoriteBucket(this.reportID, EnumBucketType.Report).subscribe(bucket => {
            this.favoriteBucket = bucket;
            this.dropdown?.updateDynamicButton('favorite-button', !!this.favoriteBucket);
        });
    }

    editReport(): void {
        this.loadingVisible = true;
        this.setXactionCountRequest();
        this.isDesigner = true;
        this.reportViewer.reportUrl = '';
        this.reportDesigner.editReport(this.reportID);
        this.dropdownProps.saveDisabled = false;
    }

    exitDesignerClick(): void {
        if (this.reportDesigner.hasUnsavedChanges()) {
            const promise = this.canDeactivateService.canDeactivate(this.canDeactivateMessage, this.canDeactivateMessageTitle);
            promise.then(result => {
                this.handleDeactivationResult(result);
                if (result) {
                    this.exitDesigner();
                }
            });
        } else {
            this.exitDesigner();
        }
    }

    exitDesigner(reportSelectionChanged: boolean = false): void {
        this.loadingVisible = true;
        this.reportDesigner.reportUrl = '';
        this.reportDesigner.resetUnsavedChangesStatus();
        if (!reportSelectionChanged) {
            setTimeout(() => {
                this.reportViewer.generateReport(this.reportID);
            });
        }
        this.dropdownProps.saveDisabled = true;
        this.isDesigner = false;
    }

    newReportClick(event): void {
        if (this.reportDesigner.hasUnsavedChanges()) {
            const promise = this.canDeactivateService.canDeactivate(this.canDeactivateMessage, this.canDeactivateMessageTitle);
            promise.then(result => {
                if (result) {
                    this.newReport(event);
                }
            });
        } else {
            this.newReport(event);
        }
    }

    newReport(event): void {
        event.clickAction.call(event.model); // TODO: right now this creates an additional tab - we probably do not want this
    }

    showInactiveSegmentsChange(): void {
        this.loadingVisible = true;
        this.reportDesigner.designReport(this.reportDesigner.hasUnsavedChanges());
    }

    onRenameReport(newName): void {
        this.reportService.renameReportLayout(this.reportID, newName).subscribe(result => {
            if (result > 0) {
                this.dropdown.repaint();
                if (!this.isDesigner) {
                    this.reportViewer.generateReport(this.reportID);
                }
                this.toast.success('Report successfully renamed to \'' + newName + '\'');
            }
        }, error => {
            this.toast.error(error.error);
        });
    }

    onAddNewReport(e): void {
        if (this.isDesigner === false) {
            // Occurred in view mode - clone currently selected report layout (or payment summary report if no existing reports)
            this.cloneSelectedReport(e);
        } else {
            // Occurred in designer mode - save current state of report and save it with new name
            this.saveAsNewReport(e);
        }
    }

    onSaveReport(e): void {
        // This method will only ever be called for updates to reports that already exist

        this.loadingVisible = true;
        this.reportDesigner.prepareForSave();

        this.reportService.getNewReportConfigId().subscribe(newReportUrl => {
            this.reportDesigner.reportConfigId = newReportUrl;
            this.reportDesigner.saveBody = this.reportDesigner.getReportConfigSaveBody();
            this.reportService.postReportConfig(this.reportDesigner.saveBody).subscribe(result => {
                if (result.success === true) {
                    const context: ReportLayoutContext = {
                        reportLayout: this.reportDesigner.reportLayout,
                        reportConfigId: this.reportDesigner.reportConfigId
                    };
                    this.reportService.updateReportLayout(context).subscribe(updateResult => {
                        if (updateResult > 0) {
                            this.dropdown.updateSelectedItemDisplayValue(context.reportLayout.name);
                            this.dropdown.repaint();
                            this.reportDesigner.resetUnsavedChangesStatus();
                            this.reportDesigner.segmentsGrid.grid.instance.cancelEditData();
                            this.reportDesigner.prepareSegmentsPopup();
                            this.reportDesigner.hasUnsavedCustomChanges = false;
                            this.appElementsService.headerComponent.unsavedChanges = false;
                            this.toast.success('Report \'' + this.reportDesigner.reportLayout.name + '\' has been saved successfully');
                            this.loadingVisible = false;
                        }
                    }, error => {
                        this.toast.error(error.error);
                        this.loadingVisible = false;
                    });
                }
            });
        });
    }

    onDeleteReport(e): void {
        this.reportService.deleteReportLayout(this.reportID).subscribe(result => {
            sessionStorage.setItem('selectedReport', undefined);
            this.removeReportFromDropdown(this.reportID);
            this.toast.success(`Report '${this.reportName}' has been successfully deleted`);
            this.reportDesigner.discardReportChanges();
            this.router.navigate([`/pages/report`]);
        });
    }

    cloneSelectedReport(newName: string): void {
        this.reportService.cloneReportLayout(this.reportID === undefined ? 0 : this.reportID , newName).subscribe(insertedReportLayout => {
            this.handleInsertedReportLayout(insertedReportLayout);
        });
    }

    saveAsNewReport(name: string): void {
        // This method will only ever be called for inserts
        this.loadingVisible = true;

        this.reportService.getNewReportConfigId().subscribe(newReportUrl => {
            this.reportDesigner.reportConfigId = newReportUrl;
            this.reportDesigner.saveBody = this.reportDesigner.getReportConfigSaveBody();
            this.reportService.postReportConfig(this.reportDesigner.saveBody).subscribe(result => {
                if (result.success === true) {
                    const newReportLayout: ReportLayout = this.helperService.deepCopyTwoPointO(this.reportDesigner.reportLayout);
                    newReportLayout.reportParId = 0;
                    newReportLayout.xml = '';
                    newReportLayout.name = name;
                    newReportLayout.showInactiveSegments = this.reportDesigner.showInactiveSegments;
                    newReportLayout.isPayment = this.reportDesigner.isPaymentReport;
                    newReportLayout.includeZeroes = this.reportDesigner.includeZeroes;
                    newReportLayout.isPublic = this.reportDesigner.isPublic;
                    newReportLayout.recurrenceId = this.reportDesigner.recurrenceId;
                    newReportLayout.functionString = this.reportDesigner.functionString;
                    newReportLayout.ruleString = this.reportDesigner.ruleString;
                    newReportLayout.attributes = this.reportDesigner.attributes;
                    newReportLayout.defaultUiViewId = this.reportDesigner.defaultUiViewId === undefined || this.reportDesigner.defaultUiViewId === 0 ? null : this.reportDesigner.defaultUiViewId;

                    const context: ReportLayoutContext = {
                        reportLayout: newReportLayout,
                        reportConfigId: this.reportDesigner.reportConfigId
                    };

                    this.reportService.insertReportLayout(context).subscribe(insertedReportLayout => {
                        this.handleInsertedReportLayout(insertedReportLayout);
                    });
                }
            });
        });
    }

    handleInsertedReportLayout(insertedReportLayout: ReportLayout): void {
        this.addReportToDropdown(insertedReportLayout.reportParId, insertedReportLayout.name);
        this.reportDesigner.resetUnsavedChangesStatus();
        this.toast.success(`Report '${insertedReportLayout.name}' has been successfully created`);
        this.reportDesigner.discardReportChanges();
        this.router.navigate([`/pages/report/${insertedReportLayout.reportParId}`]);
    }

    addReportToDropdown(id: number, name: string): void {
        this.reports.push({ id, name });
        this.reports = this.reports.sort((a, b) => a.name.localeCompare(b.name));
    }

    removeReportFromDropdown(id: number): void {
        this.reports = this.reports.filter(x => x.id !== id);
    }

    onMouseLeaveReportDesigner(): void {
        this.reportDesigner.checkForUnsavedChanges();
    }

    onLoadingStarted(): void {
        this.loadingVisible = true;
    }

    onLoadingCompleted(): void {
        this.loadingVisible = false;
        if (!localStorage.getItem(`${this.username}.selectedSellers`)){
            this.coreBotInteractionService.startInteraction(EnumCoreBotInteraction.DASelectorEmptyReport);
        }
        else {
            if (this.reportViewer?.reportParams?.context) {
                this.setXactionCountRequest();
            }
            this.xactionService.getXactionCountByPeriodSeries(this.xactionCountRequest).subscribe(res => {
                if (res.result === 0){
                    this.coreBotInteractionService.startInteraction(EnumCoreBotInteraction.EmptyPeriod);
                }
            });
        }
        this.coreBotInteractionService.runAuditInteractions(CoreBotInteractionTriggerType.ReportGeneration);
    }

    setXactionCountRequest(): void {
        this.xactionCountRequest = {
            beginDate: this.reportViewer.reportParams.context.beginDate,
            endDate: this.reportViewer.reportParams.context.endDate,
            seriesId: this.reportViewer.reportParams.context.seriesId ? this.reportViewer.reportParams.context.seriesId : this.reportViewer.reportParams.prodSeriesId
        };
    }

    onClickPermissions(): void {
        this.isAttributesPopupVisible = true;
    }

    onClickBulkHomeDefault() {
        this.isBulkHomeDefaultPopupVisible = true;
    }

    onAttributesChanged() {
        const publishRequest = new UpdatePublishRequest();
        publishRequest.resourceId = this.reportID;
        publishRequest.attributeString = this.attributePermissionString;
        publishRequest.sellerIds = this.publishedSellerIds;

        this.reportService.updateReportPublished(publishRequest).subscribe(response => {
            if (response.responseCode === coreResponseCodes.Success) {
                this.toast.success('Report attribute permissions successfully updated');
            } else {
                this.toast.error(response.message);
            }
        });
    }

    openDateSelector = (e) => {
        e.component.blur();
        this.reportViewer.openPopupAsDateSelector();
    };

    openAccountSelector = (e) => {
        e.component.blur();
        this.reportViewer.openPopupAsAccountSelector();
    };
}
