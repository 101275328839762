import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AccountManagementRoutingModule } from './account-management-routing.module';
import { AccountManagementComponent } from './account-management.component';
import { DxResponsiveBoxModule, DxDataGridModule, DxSelectBoxModule, DxButtonModule, DxFormModule,
    DxPopupModule, DxLoadPanelModule, DxTextBoxModule, DxDateBoxModule, DxScrollViewModule,
    DxTextAreaModule, DxResizableModule, 
    DxTabsModule} from 'devextreme-angular';
import { MainLayoutComponent, MainLayoutModule } from '../../layouts/main-layout/main-layout.component';
import { HierarchyModule } from '../../shared/components/hierarchy/hierarchy.module';
import { SharedModule } from '../../shared/shared.module';
import { CoreComponentModule } from 'src/app/shared/components/core-component.module';

@NgModule({
    imports: [
        CommonModule,
        AccountManagementRoutingModule,
        DxResponsiveBoxModule,
        DxDataGridModule,
        DxSelectBoxModule,
        DxButtonModule,
        DxFormModule,
        DxPopupModule,
        DxLoadPanelModule,
        DxTextBoxModule,
        DxDateBoxModule,
        DxScrollViewModule,
        DxTextAreaModule,
        DxResizableModule,
        DxTabsModule,
        MainLayoutModule,
        SharedModule,
        HierarchyModule,
        CoreComponentModule
    ],
    declarations: [
        AccountManagementComponent,
    ],
    exports: [
        AccountManagementComponent,
    ]
})
export class AccountManagementModule { }
