import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Seller } from '../models/seller';
import { SellerName } from '../models/seller-name';
import { SellerHierarchyItem } from '../models/seller-hierarchy-item';
import { Credential } from '../models/credential';
import { SellerCreationInfo } from '../models/seller-creation-info';
import { CoreRequest } from '../models/core-request';
import { CoreResponse } from '../models/core-response';
import { EnumUserGroup } from '../constants/enums';
import { SellerBatchChangesContext } from '../models/contexts/seller-batch-changes-context';
import { SellerBatchChangesResults } from '../models/seller-batch-changes-results';
import { SellerBatchChanges } from '../models/seller-batch-changes';

@Injectable()
export class SellerService {
    private baseurl: string = environment.apiEndpoint;
    private sellersURL = this.baseurl + '/seller/';
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public ssdpURL = this.sellersURL + 'ssdp';

    constructor(private http: HttpClient) {
    }

    getMe(): Observable<Seller> {
        return this.http.get<Seller>(this.sellersURL + 'me');
    }

    getSeller(userName: string): Observable<Seller> {
        return this.http.get<Seller>(this.sellersURL + userName);
    }

    getSellerWithSubordinateSetting(userName: string): Observable<Seller> {
        return this.http.get<Seller>(this.sellersURL + 'getSellerWithSubordinateSetting/' + userName);
    }

    getAllSellers(): Observable<Seller[]> {
        return this.http.get<Seller[]>(this.sellersURL);
    }

    thinGetAllSellers(includeHistory: boolean = false): Observable<Seller[]> {
        return this.http.get<Seller[]>(this.sellersURL + 'thin/' + includeHistory.toString());
    }

    getSellersCoreHome(sellerId: number): Observable<Seller[]> {
        return this.http.get<Seller[]>(this.sellersURL + 'currentSubCoreHome/' + sellerId.toString());
    }

    getSellersShallow(includeInactive: boolean = true): Observable<Seller[]> {
        return this.http.get<Seller[]>(this.sellersURL + 'shallow?includeInactive=' + includeInactive.toString());
    }

    getSellerDeep(sellerId: number): Observable<Seller> {
        return this.http.get<Seller>(this.sellersURL + 'manager/deep/' + sellerId.toString());
    }

    getSubordinatesWithCurrentHistory(sellerId: number, includeInactive: boolean = false): Observable<Seller[]> {
        return this.http.get<Seller[]>(this.sellersURL + 'currentSub/' + sellerId.toString() + '?includeInactive=' + includeInactive.toString());
    }

    getSellerMapping(): Observable<any> {
        return this.http.get<any>(this.sellersURL + 'sellerMapping');
    }

    getSellerNames(): Observable<SellerName[]> {
        return this.http.get<SellerName[]>(this.sellersURL + 'sellerNames');
    }

    getAllSellersLookup(convertIdsToNumbers: boolean = false): Observable<any[]> {
        return convertIdsToNumbers ?
            this.getSellerMapping().pipe(
                map(sellerMap => Object.entries(sellerMap).map(([k, v]) => ({ id: parseInt(k, 10), name: v })))
            ) :
            this.getSellerMapping().pipe(
                map(sellerMap => Object.entries(sellerMap).map(([k, v]) => ({ id: k, name: v })))
            );
    }

    getSellerHierarchy(): Observable<SellerHierarchyItem[]> {
        return this.http.get<SellerHierarchyItem[]>(this.sellersURL + 'hierarchy');
    }

    getHasAdminView(): Observable<boolean> {
        return this.getMe().pipe(map(me => me.userGroupId === EnumUserGroup.Administrator || me.userGroupId === EnumUserGroup.Implementer));
    }

    getActivePayeeCount(): Observable<number> {
        return this.http.get<number>(this.sellersURL + 'activePayeeCount');
    }

    getActiveSellerIds(): Observable<number[]> {
        return this.http.get<number[]>(this.sellersURL + 'activeSellerIds');
    }

    getActiveSellersByUserGroup(userGroupId: number): Observable<Seller[]> {
        return this.http.get<Seller[]>(this.sellersURL + 'byUserGroup/' + userGroupId.toString());
    }

    createSeller(sellerInfo: SellerCreationInfo): Observable<CoreResponse<Seller>> {
        const request = new CoreRequest<SellerCreationInfo>(sellerInfo, null);
        return this.http.post<CoreResponse<Seller>>(this.sellersURL + 'create', request);
    }

    // This handles batch updates from multiple grids for one specific seller
    batchUpdateSeller(context: SellerBatchChangesContext): Observable<CoreResponse<SellerBatchChangesResults>> {
        return this.http.post<CoreResponse<SellerBatchChangesResults>>(this.sellersURL + 'batchupdate', context);
    }

    // This handles batch updates from the seller grid - only updates seller name
    sellerBatchChanges(changes: SellerBatchChanges): Observable<CoreResponse<number>> {
        return this.http.post<CoreResponse<number>>(this.sellersURL + 'batchchanges', changes);
    }

    deleteSeller(seller: Seller): Observable<CoreResponse<number>> {
        return this.http.post<CoreResponse<number>>(this.sellersURL + 'delete', seller);
    }

    flattenSubordinates(topSeller: Seller, sellerArray: Seller[]) {
        sellerArray.push(topSeller);
        for (const nextSeller of topSeller.subordinates) {
            nextSeller.parentId = topSeller.id;
            this.flattenSubordinates(nextSeller, sellerArray);
        }
    }

    alias(userId: number): Observable<string> {
        return this.http.get<string>(this.sellersURL + 'alias/' + userId);
    }

    refreshUserToken(): Observable<string> {
        return this.http.get<string>(this.sellersURL + 'refreshToken');
    }
}
