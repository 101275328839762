import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Xaction } from '../models/xaction';

@Injectable()
export class AltmanService {
    private altmanURL = environment.apiEndpoint + '/altman/';

    constructor(private http: HttpClient) { }

    getPartnerMapping(): Observable<any> {
        return this.http.get<any>(this.altmanURL + 'PartnerMapping');
    }

    getDirectorMapping(): Observable<any> {
        return this.http.get<any>(this.altmanURL + 'DirectorMapping');
    }

    getProjectSplitsWhereLead(): Observable<any> {
        return this.http.get<any>(this.altmanURL + 'LeadProjectSplits');
    }

    getProjectSplitsWhereMC(): Observable<any> {
        return this.http.get<any>(this.altmanURL + 'MCProjectSplits');
    }

    getNotes(): Observable<any> {
        return this.http.get<any>(this.altmanURL + 'Notes');
    }

    UpdateFlattenedSplitsEtl(): Observable<number> {
        return this.http.put<number>(this.altmanURL + 'UpdateFlattened', null);
    }
}
